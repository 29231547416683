import React, { useState, useEffect } from "react";
import axios from "axios";
import "./PersonalInfo.css";
import Modal from "../ModalWindow/ModalWindow";
import PfpHolder from "../PfpHolder/PfpHolder";
import { useLanguage } from "../hooks/LanguageContext";

import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

const PersonalInfo = ({ user, userData }) => {
  let personalInfo;
  if (userData) {
    personalInfo = userData.personalInfo;
  }

  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState(userData?.userName || "");
  const [gender, setGender] = useState(personalInfo?.gender || "");
  const [bio, setBio] = useState(personalInfo?.bio || "");
  const [firstName, setFirstName] = useState(userData?.givenName || "");
  const [familyName, setFamilyName] = useState(userData?.familyName || "");
  const [age, setAge] = useState(personalInfo?.age || "");
  const [country, setCountry] = useState(personalInfo?.country || "");
  const [occupation, setOccupation] = useState(personalInfo?.occupation || "");
  const [preferredStyles, setPreferredStyles] = useState(personalInfo?.preferredStyles || []);
  const [preferredColors, setPreferredColors] = useState(personalInfo?.preferredColors || []);
  const [weight, setWeight] = useState(personalInfo?.weight || "");
  const [height, setHeight] = useState(personalInfo?.height || "");
  const [userNameChangeAlert, setUserNameChangeAlert] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success"); // Default severity is 'success'
  const [isUserNameTaken, setIsUserNameTaken] = useState(false);
  const [isFormIncomplete, setIsFormIncomplete] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalValue, setModalValue] = useState("");

  const { translate } = useLanguage();

  useEffect(() => {
    const user = localStorage.getItem("user");
    const parsedUser = JSON.parse(user);
    setEmail(parsedUser.email);
  }, []);

  const handleCloseMessage = () => {
    setIsModalOpen(false);
  };

  const handleUserNameChange = (event) => {
    setUserName(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleFamilyNameChange = (event) => {
    setFamilyName(event.target.value);
  };

  const handleBioChange = (event) => {
    setBio(event.target.value);
  };

  const handleAgeChange = (event) => {
    setAge(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleOccupationChange = (event) => {
    setOccupation(event.target.value);
  };

  const handleWeightChange = (event) => {
    setWeight(event.target.value);
  };

  const handleHeightChange = (event) => {
    setHeight(event.target.value);
  };

  const handleUserNameCheck = () => {
    if (userName && userName !== userData.userName) {
      try {
        axios
          // .post(
          // "http://localhost:5000/check-username",
          // { userName },
          // {
          .post(
            "https://sizeandfit.stailor.io/check-username",
            { userName },
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
              validateStatus: function (status) {
                return status === 200 || status === 409; // Treat 200 and 409 as valid statuses
              },
            }
          ) // send the data to the server
          .then((response) => {
            // Check the status code and handle the response accordingly
            if (response.status === 200) {
              setAlertSeverity("success");
              setUserNameChangeAlert(translate("personalInfo.userNameIsFree"));
              setIsUserNameTaken(false);
            } else if (response.status === 409) {
              setAlertSeverity("warning");
              setUserNameChangeAlert(translate("personalInfo.userNameIsTaken"));
              setIsUserNameTaken(true);
            } else {
              console.log("Unexpected response status:", response.status);
            }
          });
      } catch (error) {
        console.error("Failed to save personal information:", error);
      }
    } else {
      setUserNameChangeAlert("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userName || !gender || !firstName || !age || !country) {
      setIsFormIncomplete(true);
      return;
    }
    setIsFormIncomplete(false);

    // Prevent sending the form if the userName is taken
    if (isUserNameTaken) {
      alert(translate("personalInfo.userNameIsTaken"));
      return;
    }

    try {
      const data = {
        userName: userName,
        bio: bio,
        gender: gender,
        givenName: firstName,
        familyName: familyName,
        age: age,
        country: country,
        occupation: occupation,
        preferredStyles: preferredStyles,
        preferredColors: preferredColors,
        weight: weight,
        height: height,
      };
      // console.log(data);
      axios
        // .post("http://localhost:5000/update-personal-info", data, {
        .post("https://sizeandfit.stailor.io/update-personal-info", data, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }) // send the data to the server
        .then((response) => {
          if (response.status === 200 && response.data)
            try {
              // Get the existing data
              let existingUser = localStorage.getItem("user");

              // If no existing data, create an array
              // Otherwise, convert the localStorage string to an array
              existingUser = existingUser ? JSON.parse(existingUser) : {};

              if (existingUser["hasPersonalInfo"] === false) {
                // Add new data to localStorage Array
                existingUser["hasPersonalInfo"] = response.data;
                existingUser["gender"] = gender;
                existingUser["firstName"] = firstName;
                existingUser["lastName"] = familyName;

                window.location.replace(`/${userName}`);
              } else {
                existingUser["userName"] = userName;
                window.location.replace(`/${userName}`);
              }
              // Save back to localStorage
              localStorage.setItem("user", JSON.stringify(existingUser));
            } catch (err) {
              console.log(err);
            }
        })
        .catch((error) => {
          console.log(error);
          window.location.replace("/home");
        });
    } catch (error) {
      console.error("Failed to save personal information:", error);
      // Handle error cases
    }
  };

  const handlePreferredColorsClick = (event) => {
    setModalValue("color");
    setIsModalOpen(true);
  };

  const handlePreferredStyleClick = (event) => {
    setModalValue("style");
    setIsModalOpen(true);
  };

  const createModalContent = (modal) => {
    if (modal === "color") {
      const colorArr = [
        "Black",
        "Gray",
        "White",
        "Blue",
        "Red",
        "Orange",
        "Green",
        "Yellow",
        "Brown",
        "Pink",
        "Purple",
      ];
      return (
        <div className="checkbox-color-container">
          {colorArr.map((color, index) => (
            <label key={index}>
              <input
                type="checkbox"
                value={color}
                checked={preferredColors.includes(color)}
                onChange={(e) => {
                  if (e.target.checked) {
                    // Add the color to the selected colors
                    setPreferredColors((prevColors) => [...prevColors, color]);
                  } else {
                    // Remove the color from the selected colors
                    setPreferredColors((prevColors) => prevColors.filter((c) => c !== color));
                  }
                }}
              />
              <span className={color.toLowerCase()}></span>
              {color}
            </label>
          ))}
        </div>
      );
    } else if (modal === "style") {
      const styleArr = ["casual", "business casual", "sport", "athleisure", "midnight", "resort"];
      return (
        <div className="checkbox-style-container">
          {styleArr.map((style, index) => (
            <label className="checkbox-container" key={index}>
              <input
                className="style-checkbox"
                type="checkbox"
                value={style}
                checked={preferredStyles.includes(style)}
                onChange={(e) => {
                  if (e.target.checked) {
                    // Add the style to the selected styles
                    setPreferredStyles((prevStyles) => [...prevStyles, style]);
                  } else {
                    // Remove the style from the selected styles
                    setPreferredStyles((prevStyles) => prevStyles.filter((s) => s !== style));
                  }
                }}
              />
              <span className="checkmark"></span>
              {style}
            </label>
          ))}
        </div>
      );
    }
  };

  return (
    <div className="screen personal-info">
      <Grid container>
        <Grid xs={12} item display="flex" justifyContent="center">
          {/* {userData.picture ? (
            <Avatar src={userData.picture} alt="avatar" sx={{ width: 120, height: 120 }} />
          ) : (
            <Avatar alt="avatar" sx={{ bgcolor: "#EF492A", width: 120, height: 120, fontSize: 48 }}>
            </Avatar>
          )} */}
          <PfpHolder pfpUrl={user?.picture} gender={user?.gender} />
        </Grid>
      </Grid>

      <div className="text-box">
        <label className="label-text">{translate("common.email")}</label>
        <input type="text" value={email} readOnly disabled />
      </div>

      <form onSubmit={handleSubmit}>
        <div className="text-box">
          <label className="label-text">{translate("personalInfo.userName")}*</label>
          <input
            type="text"
            value={userName}
            onChange={handleUserNameChange}
            onBlur={handleUserNameCheck}
            placeholder={translate("personalInfo.userName")}
          />
        </div>
        {/* <p>{userNameChangeAlert}</p> */}
        {userNameChangeAlert && (
          <Alert variant="outlined" severity={alertSeverity} sx={{ marginBottom: "15px" }}>
            {userNameChangeAlert}
          </Alert>
        )}

        <div className="text-box">
          <label className="label-text">{translate("personalInfo.bio")}</label>
          <textarea
            type="text"
            id="bio_input"
            value={bio}
            onChange={handleBioChange}
            placeholder={translate("personalInfo.bio")}
            maxLength="100"
          />
        </div>
        <div className="text-box">
          <label className="label-text">{translate("personalInfo.gender")}*</label>
          <select
            value={gender}
            onChange={handleGenderChange}
            id="gender_input"
            className="custom-select"
            required
          >
            <option value="">{translate("personalInfo.gender")}</option>
            <option value="Male">{translate("personalInfo.male")}</option>
            <option value="Female">{translate("personalInfo.female")}</option>
          </select>
        </div>
        <div className="text-box">
          <label className="label-text">{translate("personalInfo.firstName")}*</label>
          <input
            type="text"
            value={firstName}
            onChange={handleFirstNameChange}
            placeholder={translate("personalInfo.firstName")}
          />
        </div>
        <div className="text-box">
          <label className="label-text">{translate("personalInfo.familyName")}</label>
          <input
            type="text"
            value={familyName}
            onChange={handleFamilyNameChange}
            placeholder={translate("personalInfo.familyName")}
          />
        </div>
        <div className="text-box">
          <label className="label-text">{translate("personalInfo.age")}*</label>
          <input
            type="text"
            id="age_input"
            value={age}
            onChange={handleAgeChange}
            placeholder={translate("personalInfo.age")}
            required
          />
        </div>
        <div className="text-box">
          <label className="label-text">{translate("personalInfo.country")}*</label>
          <input
            type="text"
            id="country_input"
            value={country}
            onChange={handleCountryChange}
            placeholder={translate("personalInfo.country")}
            required
          />
        </div>
        <div className="text-box">
          <label className="label-text">{translate("personalInfo.occupation")}</label>
          <input
            type="text"
            id="occupation_input"
            value={occupation}
            onChange={handleOccupationChange}
            placeholder={translate("personalInfo.occupation")}
          />
        </div>
        <div className="text-box">
          <label className="label-text">{translate("personalInfo.preferredStyles")}</label>
          <input
            type="text"
            id="preferredStyles_input"
            value={preferredStyles.join(", ")}
            onClick={() => handlePreferredStyleClick(true)}
            // onChange={handlePreferredStylesChange}
            placeholder={translate("personalInfo.preferredStyles")}
            readOnly
          />
        </div>
        <div className="text-box">
          <label className="label-text">{translate("personalInfo.preferredColors")}</label>
          <input
            type="text"
            id="preferredColors_input"
            value={preferredColors.join(", ")}
            onClick={handlePreferredColorsClick}
            placeholder={translate("personalInfo.preferredColors")}
            readOnly
          />
        </div>
        <div className="text-box">
          <label className="label-text">{translate("personalInfo.weight")}</label>
          <input
            type="number"
            id="weight_input"
            value={weight}
            onChange={handleWeightChange}
            placeholder={translate("personalInfo.weight")}
          />
        </div>
        <div className="text-box">
          <label className="label-text">{translate("personalInfo.height")}</label>
          <input
            type="number"
            id="height_input"
            value={height}
            onChange={handleHeightChange}
            placeholder={translate("personalInfo.height")}
          />
        </div>
        {/* <p>* required fields</p> */}
        <p>{translate("personalInfo.requiredFields")}</p>
        {isFormIncomplete && (
          // <div className="warning-box">{translate("personalInfo.requiredFields")}</div>
          <Alert variant="outlined" severity="warning">
            {translate("personalInfo.requiredFields")}
          </Alert>
        )}
        <button className="btn btn-medium btn-hover" type="submit">
          {translate("personalInfo.btnSubmit")}
        </button>
      </form>

      {isModalOpen && (
        <Modal onClose={handleCloseMessage}>
          {createModalContent(modalValue)}
          <button className="btn btn-big btn-bgcolor-stailor" onClick={() => setIsModalOpen(false)}>
            Confirm
          </button>
        </Modal>
      )}
    </div>
  );
};

export default PersonalInfo;
