import axios from "axios";

export function trackButtonClick(link) {
  const url = new URL(link);
  const storeUrl = url.host;
  const user = localStorage.getItem("user");
  const parsedUser = JSON.parse(user);
  const userData = parsedUser.token ? parsedUser.token : parsedUser.id;
  const isAuthUser = parsedUser.token ? true : false;

  const clickData = {
    link: link,
    storeUrl: storeUrl,
    user: userData,
    isAuthUser: isAuthUser,
  };

  try {
    // console.log(data);
    axios
      // .post("http://localhost:5000/record-click", clickData)
      .post("https://sizeandfit.stailor.io/record-click", clickData)
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        // console.log(error);
        if (error.response.status === 401) {
          // Invalid/expired token
          // Remove the user session from local storage
          localStorage.removeItem("user");
          // Redirect to the home page
          window.location.href = "/";
        }
      });
  } catch (error) {
    console.error("Failed to record the click activity:", error);
  }
}
