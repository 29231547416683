import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Badge from "@mui/material/Badge";

const SlidingMenuButton = ({ btnText, handleAction, hasBadge = false, unapprovedStylesCount }) => {
  return (
    <button className="btn btn-menu-options" onClick={handleAction}>
      <Typography sx={{ textAlign: "left" }}>{btnText}</Typography>
      {hasBadge && <Badge badgeContent={unapprovedStylesCount} color="info"></Badge>}
      <ArrowRightIcon fontSize="medium" />
    </button>
  );
};

export default SlidingMenuButton;
