import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import useLikedItems from "../hooks/useLikedItems";
import GarmentPreview from "../GarmentPreview/GarmentPreview";
import Loading from "../Loading/Loading";
import { useLanguage } from "../hooks/LanguageContext";
import { trackButtonClick } from "../../functions/trackClicks";
import "./Product.css";
import Typography from "@mui/material/Typography";
import Coupon from "../Coupon/Coupon";

const Product = ({ isAuthenticated }) => {
  const [productData, setProductData] = useState();
  const { likedItems } = useLikedItems(isAuthenticated);
  const { translate } = useLanguage();
  const navigate = useNavigate();
  const { productId } = useParams(); // Get the productId from the URL

  const openProduct = () => {
    trackButtonClick(productData?.url);
    window.open(productData?.url, "_blank");
  };

  useEffect(() => {
    // Fetch product data based on the productId
    async function fetchProductData() {
      try {
        // const response = await axios.get(`http://localhost:5000/products/get-product/${productId}`);
        const response = await axios.get(
          `https://sizeandfit.stailor.io/products/get-product/${productId}`
        );
        setProductData(response.data);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    }

    fetchProductData();
  }, [productId]);

  if (productData && productData !== "undefined") {
    return (
      <div className="screen">
        <GarmentPreview
          previewObj="product"
          likedItems={likedItems}
          product={productData}
          img={productData?.imgUrl}
        >
          <div className="container-btns">
            <button
              className="btn btn-medium"
              onClick={() => {
                navigate(-1);
              }}
            >
              <Typography>{translate("product.back")}</Typography>
            </button>
            <button className="btn btn-medium bgcolor-stailor bold" onClick={openProduct}>
              <Typography>{translate("product.view")}</Typography>
            </button>
          </div>
          <div className="container-product-info">
            <Typography variant="h6" gutterBottom>
              {translate("product.productInfo")}
            </Typography>
            <div className="info-item">
              <Typography variant="string" sx={{ fontWeight: 600 }}>
                {translate("product.price")}:
              </Typography>{" "}
              <Typography variant="string">
                {productData?.price} {productData?.currency}
              </Typography>
            </div>
            {productData?.storeUrl && (
              <div className="info-item">
                <Typography variant="string" sx={{ fontWeight: 600 }}>
                  {translate("product.store")}:
                </Typography>
                <Typography variant="string"> {productData?.storeUrl}</Typography>
              </div>
            )}
            {/* {productData.description && (
              <div className="info-item">
                <Typography variant="string">{translate("product?.description")}:</Typography> {productData?.description}
              </div>
            )} */}
          </div>
        </GarmentPreview>
        <Coupon />
      </div>
    );
  }

  return (
    <div className="screen screen-recommendations">
      <Loading />
    </div>
  );
};

export default Product;
