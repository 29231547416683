import React, { useState, useEffect, useRef } from "react";
import "./SlidingMenu.css";
import SignoutButton from "../SignoutButton/SignoutButton";
import SlidingMenuButton from "../SlidingMenuButton/SlidingMenuButton";
import LoginButton from "../LoginButton/LoginButton";
import LanguageToggle from "../LanguageToggle/LanguageToggle";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../hooks/LanguageContext";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Badge from "@mui/material/Badge";

const SlidingMenu = ({ userIsAuthenticated, profileLink, userType, unapprovedStylesCount }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null); // Create a ref for the menu container
  const { translate } = useLanguage();

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  // Function to set height
  function setMenuHeight() {
    const refElement = document.querySelector(".main-content");
    const targetElement = document.querySelector(".burger-menu");
    const navbarElement = document.querySelector("nav.navbar");

    if (refElement && targetElement && navbarElement) {
      const navbarHeight = navbarElement.offsetHeight;
      const targetElementNewHeight = refElement.offsetHeight - navbarHeight;
      targetElement.style.height = `${targetElementNewHeight}px`;
    }
  }

  // Initial set height
  setMenuHeight();

  const handleBtnClick = (page) => {
    toggleMenu();

    switch (page) {
      case "Home":
        navigate("/home");
        break;
      case "Profile":
        navigate(`/${profileLink}`);
        break;
      case "botAI":
        navigate("/aiassistant");
        break;
      case "FeedStyles":
        navigate("/feed-styles");
        break;
      case "FeedProducts":
        navigate("/feed-products");
        break;
      case "Coupons":
        navigate("/coupons");
        break;
      case "Settings":
        navigate("/user-settings");
        break;
      case "Unapproved Styles":
        navigate("/moderator-unapproved-styles");
        break;
      default:
        navigate("/");
        break;
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // If the click occurs outside the menu, close it
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    // Clean up the event listener when the component unmounts or the menu closes
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMenuOpen]);

  return (
    <>
      {!isMenuOpen && (
        <Badge badgeContent={unapprovedStylesCount} color="info">
          <MenuIcon fontSize="large" sx={{ cursor: "pointer" }} onClick={toggleMenu} />
        </Badge>
      )}
      <div className={`burger-menu ${isMenuOpen ? "burger-menu-open" : ""}`} ref={menuRef}>
        <SlidingMenuButton
          btnText={translate("burgerMenu.home")}
          handleAction={() => handleBtnClick("Home")}
        />
        <SlidingMenuButton
          btnText={translate("burgerMenu.profile")}
          handleAction={() => handleBtnClick("Profile")}
        />
        <SlidingMenuButton
          btnText={translate("burgerMenu.feedProducts")}
          handleAction={() => handleBtnClick("FeedProducts")}
        />
        <SlidingMenuButton
          btnText={translate("burgerMenu.feedStyles")}
          handleAction={() => handleBtnClick("FeedStyles")}
        />
        <SlidingMenuButton
          btnText={translate("burgerMenu.assistantAI")}
          handleAction={() => handleBtnClick("botAI")}
        />
        <SlidingMenuButton
          btnText={translate("burgerMenu.coupons")}
          handleAction={() => handleBtnClick("Coupons")}
        />
        {/* <SlidingMenuButton
          btnText="Списък с магазини"
          handleAction={() => handleBtnClick("StoreList")}
        /> */}
        {userIsAuthenticated ? (
          <>
            <SlidingMenuButton
              btnText={translate("burgerMenu.settings")}
              handleAction={() => handleBtnClick("Settings")}
            />
            {(userType === "editor" || userType === "admin") && (
              <SlidingMenuButton
                btnText={translate("burgerMenu.unapprovedStyles")}
                hasBadge
                unapprovedStylesCount={unapprovedStylesCount}
                handleAction={() => handleBtnClick("Unapproved Styles")}
              />
            )}
            <SignoutButton />
          </>
        ) : (
          <LoginButton />
        )}
        <Box sx={{ display: "flex", flexDirection: "column", marginTop: "20px", gap: 2 }}>
          <LanguageToggle />
          <a
            href="https://stailor.io/en/term-and-conditions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography>{translate("burgerMenu.generalTerms")}</Typography>
          </a>
          <a href="https://stailor.io/en/privacy-policy/" target="_blank" rel="noopener noreferrer">
            <Typography>{translate("burgerMenu.privacyPolicy")}</Typography>
          </a>
        </Box>
      </div>
      {isMenuOpen && (
        // <div className="btn-burger-menu" onClick={toggleMenu}>
        <CloseIcon fontSize="large" sx={{ cursor: "pointer" }} onClick={toggleMenu} />
        // </div>
      )}
    </>
  );
};

export default SlidingMenu;
