import "./GarmentPreview.css";
import ActionButtonsBar from "../ActionButtonsBar/ActionButtonsBar";
import Typography from "@mui/material/Typography";

const GarmentPreview = ({
  previewObj,
  likedItems,
  product,
  img,
  hasTitle = true,
  hasDescription = false,
  isLikable = true,
  children,
}) => {
  const sharingData = { url: window.location.pathname, name: product.name };

  return (
    <div className="container-garment-preview">
      {hasTitle && (
        <div className="top-text">
          <Typography variant="h5">{product.name}</Typography>
        </div>
      )}
      <div className="container-product">
        <img src={img} alt={product.name} />
        {isLikable && (
          <ActionButtonsBar
            previewObj={previewObj}
            likedItems={likedItems}
            product={product}
            img={img}
            sharingData={sharingData}
          />
        )}
      </div>
      {hasDescription && <p className="txt-style">{product.description}</p>}
      {children !== "undefined" && children}
    </div>
  );
};
export default GarmentPreview;
