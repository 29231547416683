import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import useFetch from "../hooks/useFetch";
import { useLanguage } from "../hooks/LanguageContext";
import Modal from "../ModalWindow/ModalWindow";
import "./Login.css";
import Typography from "@mui/material/Typography";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  const { translate } = useLanguage();

  // const { handleGoogle, loading, error } = useFetch("http://localhost:5000/auth/loginGoogle");
  const { handleGoogle, loading, error } = useFetch(
    "https://sizeandfit.stailor.io/auth/loginGoogle"
  );

  useEffect(() => {
    /* global google */
    if (window.google) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });

      google.accounts.id.renderButton(document.getElementById("loginDiv"), {
        theme: "filled_black",
        text: "continue_with",
        size: "large",
        width: 354,
        locale: "en",
      });
    }
  }, [handleGoogle]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = {
        email: email,
        pass: password,
      };

      // const response = await axios.post(`http://localhost:5000/auth/login`, { data });
      const response = await axios.post(`https://sizeandfit.stailor.io/auth/login`, { data });
      if (response.data?.user) {
        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(response.data?.user));
        window.location.href = "/home";
      }
    } catch (err) {
      if (err.request.status === 400) {
        let parsedErrMessage = JSON.parse(err.request.response);
        alert(parsedErrMessage.message);
      } else {
        alert("An error occurred while logging in");
      }
    }
  };

  const handleClick = () => {
    setShowMessage(true);
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
    setEmail("");
  };

  const handleForgotPassSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post("https://sizeandfit.stailor.io/auth/forgotPassword", {
        email: email,
      });
      setEmail("");
      alert(response.data);
    } catch (error) {
      alert("An error occurred, please try again later.");
    }
  };

  return (
    <div className="screen">
      <img className="image-sign" src="./signPhoto.png" alt="Login" />
      <Typography variant="h4" gutterBottom>
        {translate("screenLogin.textMainMessage")}
      </Typography>
      <form onSubmit={handleSubmit} className="form-auth">
        <label>
          {translate("common.email")}
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={translate("screenLogin.emailPlaceholder")}
            required
          />
        </label>
        <label>
          {translate("common.password")}
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder={translate("screenLogin.passwordPlaceholder")}
            required
          />
        </label>
        <button type="submit" className="btn btn-big btn-bgcolor-stailor">
          <Typography>{translate("common.loginText")}</Typography>
        </button>
      </form>
      <Typography
        sx={{ margin: "20px 0", textDecoration: "underline", cursor: "pointer" }}
        onClick={handleClick}
      >
        {translate("screenLogin.forgotPassword")}
      </Typography>
      <Typography variant="h5" gutterBottom>
        {translate("screenLogin.loginWithGoogle")}
      </Typography>
      <div className="container-btn-authentication" id="loginDiv"></div>
      <Typography sx={{ marginTop: "20px" }}>{translate("screenLogin.noAccountMsg")}</Typography>
      <Link to="/signup">
        <Typography sx={{ textDecoration: "underline" }}>
          {translate("screenLogin.signUpLink")}
        </Typography>
      </Link>
      {showMessage && (
        <Modal onClose={handleCloseMessage}>
          <div>
            <Typography variant="h5" sx={{ padding: "15px 0" }}>
              {translate("screenLogin.resetPasswordText")}
            </Typography>
            <form onSubmit={handleForgotPassSubmit} className="form-auth">
              <label>
                <Typography>{translate("common.email")}:</Typography>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </label>
              <button className="btn btn-big btn-bgcolor-stailor" type="submit">
                <Typography>{translate("screenLogin.resetPasswordBtn")}</Typography>
              </button>
            </form>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Login;
