import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./FeedStyles.css";
import GarmentPreview from "../GarmentPreview/GarmentPreview";
import ActionButtonsBar from "../ActionButtonsBar/ActionButtonsBar";
import useLikedItems from "../hooks/useLikedItems";
import ProfileBar from "../ProfileBar/ProfileBar";
import { useLanguage } from "../hooks/LanguageContext";
import Typography from "@mui/material/Typography";

const FeedStyle = ({ stylesData, isAuthenticated }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [expandedStates, setExpandedStates] = useState({});
  const { likedItems } = useLikedItems(isAuthenticated);
  const { translate } = useLanguage();

  const toggleDescription = (styleId) => {
    setExpandedStates((prevStates) => ({
      ...prevStates,
      [styleId]: !prevStates[styleId],
    }));
  };

  const renderDescription = (description, styleId) => {
    const maxWords = 12;
    const words = description.split(" ");
    const isExpanded = expandedStates[styleId];

    if (isExpanded || words.length <= maxWords) {
      return description;
    }

    const truncatedDescription = words.slice(0, maxWords).join(" ");
    return `${truncatedDescription} ...`;
  };

  const feed = () => {
    if (stylesData && Array.isArray(stylesData)) {
      const filteredStyles = selectedCategory
        ? stylesData.filter((style) => style.category === selectedCategory)
        : stylesData;

      const styles = filteredStyles.map((style) => {
        const img = style.image;
        const styleURL = `${window.location.origin}/style/${style._id}`;
        const sharingData = { url: styleURL, text: style.name };

        // console.log(styleLikes[style._id]);

        return (
          <div key={style._id} className="container-style">
            <ProfileBar
              userName={style.createdByUser?.userName}
              userPic={style.createdByUser?.picture}
            />
            <Link to={styleURL}>
              <GarmentPreview
                previewObj="style"
                likedItems={likedItems}
                product={style}
                img={img}
                hasDescription={false}
                isLikable={false}
              />
            </Link>
            <div className="container-action-buttons">
              <ActionButtonsBar
                previewObj="style"
                likedItems={likedItems}
                product={style}
                img={img}
                sharingData={sharingData}
              />
            </div>

            <p className="style-description">
              {renderDescription(style.description, style._id)}
              {!expandedStates[style._id] && (
                <span
                  className="span-toggle-description"
                  onClick={() => toggleDescription(style._id)}
                >
                  &nbsp;{translate("feedStyle.more")}
                </span>
              )}
              {expandedStates[style._id] && (
                <span
                  className="span-toggle-description"
                  onClick={() => toggleDescription(style._id)}
                >
                  &nbsp;{translate("feedStyle.less")}
                </span>
              )}
            </p>
          </div>
        );
      });

      return styles;
    }
  };

  return (
    <div className="screen screen-feedstyles">
      <div className="top-text">
        <Typography variant="h5" gutterBottom>
          {translate("feedStyle.findStyleInspirations")}
        </Typography>
        <Typography gutterBottom>{translate("common.stylesCategories.chooseCategory")}</Typography>
      </div>
      <div className="container-filters-styles">
        <button
          className={`btn-styles${selectedCategory === "casual" ? " active" : ""}`}
          onClick={() => setSelectedCategory("casual")}
        >
          {translate("common.stylesCategories.casual")}
        </button>
        <button
          className={`btn-styles${selectedCategory === "business-casual" ? " active" : ""}`}
          onClick={() => setSelectedCategory("business-casual")}
        >
          {translate("common.stylesCategories.businessCasual")}
        </button>
        <button
          className={`btn-styles${selectedCategory === "sportswear" ? " active" : ""}`}
          onClick={() => setSelectedCategory("sportswear")}
        >
          {translate("common.stylesCategories.sportswear")}
        </button>
        <button
          className={`btn-styles${selectedCategory === "athleisure" ? " active" : ""}`}
          onClick={() => setSelectedCategory("athleisure")}
        >
          {translate("common.stylesCategories.athleisure")}
        </button>
        <button
          className={`btn-styles${selectedCategory === "midnight" ? " active" : ""}`}
          onClick={() => setSelectedCategory("midnight")}
        >
          {translate("common.stylesCategories.midnight")}
        </button>
        <button
          className={`btn-styles${selectedCategory === "resort" ? " active" : ""}`}
          onClick={() => setSelectedCategory("resort")}
        >
          {translate("common.stylesCategories.resort")}
        </button>
        <button
          className={`btn-styles${selectedCategory === null ? " active" : ""}`}
          onClick={() => setSelectedCategory(null)}
        >
          {translate("common.stylesCategories.all")}
        </button>
      </div>

      {feed()}
    </div>
  );
};

export default FeedStyle;
