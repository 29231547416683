import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function ServicesScreen({ userServices }) {
  const [query, setQuery] = useState("");

  const filteredServices = userServices
    ? userServices.filter((service) => {
        return service.title.toLowerCase().includes(query.toLowerCase());
      })
    : [];

  return (
    <div className="screen-services">
      {filteredServices.map((service) => (
        <Accordion key={service._id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography align="left">{service.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography align="left" variant="body2" sx={{ whiteSpace: "pre-line" }}>
              {service.description}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default ServicesScreen;
