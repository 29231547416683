import React from "react";
import "./Loading.css";
import Typography from "@mui/material/Typography";
import { useLanguage } from "../hooks/LanguageContext";

const Loading = () => {
  const { translate } = useLanguage();

  return (
    <div className="loading-screen">
      <div className="spinner"></div>
      <Typography>{translate("common.loading")}...</Typography>
    </div>
  );
};

export default Loading;
