import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import "./ProfileBar.css";

const ProfileBar = ({ userName, userPic }) => {
  return (
    <div className="container-profile-bar">
      <Grid container>
        <Grid item xs={2}>
          <Link to={`/${userName}`}>
            {userPic ? (
              <Avatar src={userPic} alt="avatar" />
            ) : (
              <Avatar alt="avatar" sx={{ bgcolor: "#EF492A" }}></Avatar>
            )}
          </Link>
        </Grid>
        <Grid item xs={10} display="flex" justifyContent="flex-start" alignItems="center">
          <Link to={`/${userName}`}>
            <Typography>{userName}</Typography>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfileBar;
