import { Link } from "react-router-dom";
import { useLanguage } from "../hooks/LanguageContext";
import "./Price.css";
import Typography from "@mui/material/Typography";

const Price = ({ handleClick }) => {
  const { translate } = useLanguage();

  // Creating the categories array with values depending on the gender (shop department)
  let pricesList = [
    { value: "<100", text: translate("price.upTo100") },
    { value: "<300", text: translate("price.upTo300") },
    { value: "<500", text: translate("price.upTo500") },
    { value: ">500", text: translate("price.over500") },
  ];

  // Map the categories array and create the html code for each garment cateogry
  const mapPricesList = pricesList.map((price, index) => {
    return (
      <button
        className="btn btn-big"
        key={index}
        onClick={handleClick}
        name="price"
        value={price.value}
      >
        {price.text}
      </button>
    );
  });

  return (
    <div className="screen screen-price-preference">
      <div className="top-text">
        <Typography variant="h6">{translate("price.choosePriceRange")}</Typography>
      </div>
      <div className="container-garment-preference">
        <div className="container-gender">{mapPricesList}</div>
        <Link to={"/style-recommendation"}>
          <button className="button_style">{translate("common.startOver")}</button>
        </Link>
      </div>
    </div>
  );
};

export default Price;
