import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLanguage } from "../components/hooks/LanguageContext";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Typography from "@mui/material/Typography";

const UnapprovedStyles = ({ styles, userType }) => {
  const navigate = useNavigate();
  const { translate } = useLanguage();

  useEffect(() => {
    if (userType !== "editor" && userType !== "admin") navigate("/home");
  }, [userType, navigate]);

  return (
    <div className="screen">
      <Typography variant="h5" gutterBottom>
        {translate("unapprovedStyles.textMainMessage")}
      </Typography>
      <ImageList sx={{ width: "inherit" }} cols={3}>
        {styles.map((style) => (
          <Link to={`/style/${style._id}`} key={style._id}>
            <ImageListItem>
              <img src={style.image} alt="style thumbnail" loading="lazy" />
            </ImageListItem>
          </Link>
        ))}
      </ImageList>
    </div>
  );
};

export default UnapprovedStyles;
