import "./ImgThumbnail.css";
const ImgThumbnail = ({ img, isExternalImg = true, isApproved = true }) => {
  let imgSrc;
  if (isExternalImg) imgSrc = img;
  else imgSrc = `${process.env.PUBLIC_URL}/${img}`;

  return (
    <div className="container-img-thumbnail">
      {!isApproved && <div className="not-approved">Pending Approval</div>}
      <img src={imgSrc} alt="garment thumbnail" />
    </div>
  );
};

export default ImgThumbnail;
