import "./Gender.css";
import { Link } from "react-router-dom";
import { useLanguage } from "../hooks/LanguageContext";
import Typography from "@mui/material/Typography";

const Gender = ({ handleClick }) => {
  const { translate } = useLanguage(); // Use the translate function from the useLanguage hook

  return (
    <div className="screen screen-gender">
      <div className="top-text">
        <Typography variant="h5" gutterBottom>
          {translate("gender.whichDepartment")}
        </Typography>
        <Typography variant="h6">{translate("gender.startingPoint")}</Typography>
      </div>
      <div className="container-gender">
        <button className="btn btn-big" onClick={handleClick} name="gender" value="female">
          {translate("gender.women")}
        </button>
        <button className="btn btn-big disabled" name="gender" value="male" disabled>
          {translate("gender.men")}
        </button>

        <div className="hr-with-text">
          <Typography variant="string">{translate("gender.orTryOut")}</Typography>
        </div>

        <Link to="/aiassistant">
          <button className="btn btn-big" name="gender" value="female">
            <Typography>{translate("gender.chatWithAIStylist")}</Typography>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Gender;
