import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  Typography,
  List,
  ListItem,
  ListItemText,
  CardMedia,
  Grid,
} from "@mui/material";

function StoresScreen() {
  const [stores, setStores] = useState([]);
  const [query, setQuery] = useState("");
  const [selectedStore, setSelectedStore] = useState(null);

  useEffect(() => {
    axios
      // .get("http://localhost:5000/get-stores")
      .get("https://sizeandfit.stailor.io/get-stores")
      .then((response) => setStores(response.data))
      .catch((error) => console.log(error));
  }, []);

  // console.log(stores);

  const handleSearchChange = (event) => {
    setSelectedStore(null);
    setQuery(event.target.value);
  };

  const handleStoreClick = (store) => {
    if (selectedStore && selectedStore._id === store._id) {
      setSelectedStore(null);
    } else {
      setSelectedStore(store);
    }
  };

  const filteredStores = stores.filter((store) => {
    return store.name.toLowerCase().includes(query.toLowerCase());
  });

  return (
    <div className="screen screen-store">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <header className="App-header">
            <div className="search-container">
              <TextField
                label="Търси магазин"
                placeholder="Търси"
                value={query}
                onChange={handleSearchChange}
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <span role="img" aria-label="Search">
                      &#128269;
                    </span>
                  ),
                }}
              />
            </div>
          </header>

          <div className="store-container">
            <List>
              {filteredStores.map((store) => (
                <div key={store._id}>
                  <ListItem
                    // button
                    onClick={() => handleStoreClick(store)}
                    style={{
                      backgroundColor:
                        selectedStore && selectedStore._id === store._id ? "white" : "#F6F6F6",
                      marginBottom: "10px",
                      borderRadius: "10px",
                      padding: "8px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <ListItemText primary={store.name} />
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {selectedStore && selectedStore._id === store._id ? "↓" : "→"}{" "}
                    </span>
                  </ListItem>
                  {selectedStore && selectedStore._id === store._id && (
                    <div className="store-details">
                      <CardMedia
                        component="img"
                        alt={selectedStore.name}
                        height="140"
                        image={selectedStore.logoPath}
                        title={selectedStore.name}
                      />
                      <Typography variant="body2" color="textSecondary">
                        {selectedStore.description}
                      </Typography>
                    </div>
                  )}
                </div>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default StoresScreen;
