import React, { useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Navbar.css";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";

const Navbar = () => {
  const [lang, setLang] = useState("bg");
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Translation texts
  const translations = {
    en: {
      comingSoon: "Coming soon",
      featureSoon: "This feature will be available soon.",
      ok: "OK",
    },
    bg: {
      comingSoon: "Очаквайте скоро",
      featureSoon: "Тази функционалност ще бъде достъпна скоро.",
      ok: "Назад",
    },
  };

  return (
    <nav className="navbar">
      <div className="nav-item">
        <Link to="/home">
          <HomeOutlinedIcon fontSize="large" />
        </Link>
      </div>
      <div className="nav-item">
        <Link to="/feed-products">
          <StorefrontOutlinedIcon fontSize="large" />
        </Link>
      </div>
      <div className="nav-item">
        <Link to="/feed-styles">
          <i>
            <img
              src={process.env.PUBLIC_URL + "/stailor_logo.png"}
              alt="Stailor Logo"
              className="icon"
            />
          </i>
        </Link>
      </div>
      <div className="nav-item">
        <Link to="/style-recommendation">
          <QuizOutlinedIcon fontSize="large" />
        </Link>
      </div>
      <div className="nav-item">
        <Link to="/coupons">
          <LocalOfferOutlinedIcon fontSize="large" />
        </Link>
      </div>
      {showModal && (
        <div className="modal">
          <div className="modal-content">
            <span className="close-modal" onClick={handleCloseModal}>
              &times;
            </span>
            <h2>{translations[lang].comingSoon}</h2>
            <p>{translations[lang].featureSoon}</p>
            <button className="btn btn-big bgcolor-stailor" onClick={handleCloseModal}>
              {translations[lang].ok}
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
