import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Modal from "../ModalWindow/ModalWindow";
import "./LikeButton.css";
import { useLanguage } from "../hooks/LanguageContext";

import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Typography from "@mui/material/Typography";

const LikeButton = ({ previewObj, likedItems, objectId }) => {
  const [liked, setLiked] = useState(false);
  const [styleLikesCount, setStyleLikesCount] = useState();
  const [showMessage, setShowMessage] = useState(false);

  const { translate } = useLanguage();

  const email = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).email : "";

  useEffect(() => {
    let isLiked;
    if (previewObj === "product") {
      isLiked = likedItems.products.some((product) => product === objectId);
    } else if (previewObj === "style") {
      isLiked = likedItems.styles.some((style) => style === objectId);
    }
    setLiked(isLiked);
  }, [objectId, likedItems, previewObj]); // added likedItems to dependencies

  const handleLike = async () => {
    try {
      const path = previewObj === "product" ? "like-product" : "like-style";
      // const response = await axios.post(`http://localhost:5000/${path}`, {
      const response = await axios.post(`https://sizeandfit.stailor.io/${path}`, {
        objectId,
        email,
      });

      if (response.status === 200) {
        setLiked(true);
      } else {
        console.error("Failed to like product:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUnlike = async () => {
    try {
      const path = previewObj === "product" ? "unlike-product" : "unlike-style";
      // const response = await axios.delete(`http://localhost:5000/${path}`, {
      const response = await axios.delete(`https://sizeandfit.stailor.io/${path}`, {
        data: {
          objectId,
          email,
        },
      });

      if (response.status === 200) {
        setLiked(false);
      } else {
        console.error("Failed to unlike product:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();

    if (!email) {
      setShowMessage(true);
      let carouselArrows = document.querySelectorAll(".carousel-slider > button");
      if (carouselArrows && carouselArrows.length > 1) {
        carouselArrows.forEach((arrow) => {
          arrow.style.display = "none";
        });
      }
    } else {
      if (liked) {
        handleUnlike();
      } else {
        handleLike();
      }
    }
  };

  useEffect(() => {
    const fetchStyleLikesCount = async () => {
      try {
        // Fetch all records from the favstyles table
        const countStylesLikes = await axios.get(
          // `http://localhost:5000/count-style-likes/${objectId}`
          `https://sizeandfit.stailor.io/count-style-likes/${objectId}`
        );

        if (countStylesLikes.status === 200) {
          const likesCount = countStylesLikes.data;
          setStyleLikesCount(likesCount);
        } else {
          console.log("Failed to fetch favstyles data.");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (objectId && previewObj === "style") fetchStyleLikesCount();
  }, [objectId, previewObj, liked]);

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  return (
    <>
      <div className="btn-like" onClick={handleClick}>
        {styleLikesCount > 0 && <Typography variant="caption">{styleLikesCount}</Typography>}
        {liked ? (
          <FavoriteIcon sx={{ fontSize: "30px", color: "#EF492A" }} />
        ) : (
          <FavoriteBorderIcon sx={{ fontSize: "30px" }} />
        )}
      </div>

      {showMessage && (
        <Modal onClose={handleCloseMessage}>
          <Typography variant="h5" gutterBottom sx={{ padding: "15px 0" }}>
            {translate("common.pleaseLogin")}
          </Typography>
          <Link to="/login">
            <button className="btn btn-big bgcolor-stailor">
              <Typography>{translate("common.loginText")}</Typography>
            </button>
          </Link>
          <Typography sx={{ marginTop: "20px" }}>{translate("common.noAccountMsg")}</Typography>
          <Link to="/signup">
            <Typography sx={{ textDecoration: "underline" }}>
              {translate("common.signUpText")}
            </Typography>
          </Link>
        </Modal>
      )}
    </>
  );
};

export default LikeButton;
