import React from "react";
import "./ColorPreference.css";
import { Link } from "react-router-dom";
import { useLanguage } from "../hooks/LanguageContext";
import Typography from "@mui/material/Typography";

const ColorPreference = ({ handleClick }) => {
  const { translate } = useLanguage();

  const colors = [
    "black",
    "white",
    "gray",
    "beige",
    "red",
    "pink",
    "blue",
    "green",
    "purple",
    "orange",
    "yellow",
    "brown",
  ];

  const mapColors = colors.map((color, index) => {
    let btnClass = `btn-color ${color}`;
    return (
      <button
        className={btnClass}
        key={index}
        onClick={handleClick}
        name="color"
        value={color}
      ></button>
    );
  });

  return (
    <div className="screen screen-color-preference">
      <div className="top-text">
        <Typography variant="h6">{translate("colorPreference.chooseColor")}</Typography>
      </div>
      <div className="container-colors">{mapColors}</div>
      <Link to={"/style-recommendation"}>
        <button className="button_style">{translate("common.startOver")}</button>
      </Link>
    </div>
  );
};

export default ColorPreference;
