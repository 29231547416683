import React from "react";
import "./SignoutButton.css";
import { useLanguage } from "../hooks/LanguageContext";
import Typography from "@mui/material/Typography";

const SignoutButton = () => {
  const { translate } = useLanguage();

  const handleSignout = () => {
    // Remove the user session from local storage
    localStorage.removeItem("user");

    // Redirect to the home page
    window.location.href = "/";
  };

  return (
    <button className="btn btn-medium btn-signout" onClick={handleSignout}>
      <Typography>{translate("common.signOutText")}</Typography>
    </button>
  );
};

export default SignoutButton;
