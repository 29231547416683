import React, { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
// import axios from "axios";
import Gender from "../Gender/Gender";
import Garment from "../Garment/Garment";
import ColorPrefference from "../ColorPreference/ColorPreference";
import Price from "../Price/Price";
import LoadingScreen from "../LoadingScreen/LoadingScreen"; // Not in use for the moment
import Recommendations from "../Recommendations/Recommendations";
import "./GarmentPreference.css";

const GarmentPreference = ({ isAuthenticated }) => {
  const [preferenceData, setPreferenceData] = useState({
    gender: "",
    garmentType: "",
    color: "",
    price: "",
    fit: "",
  });

  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    setPreferenceData((prevPreferenceData) => {
      return {
        ...prevPreferenceData,
        [name]: value,
      };
    });

    if (name === "gender") {
      navigate("garment");
    }
    if (name === "garmentType") {
      navigate("color");
    }
    if (name === "color") {
      navigate("price");
    }

    if (name === "price") {
      navigate("recommendations");
    }
  };
  //   console.log(preferenceData);

  // const getRecommendation = () => {
  //   console.log("preference data is", preferenceData);
  //   axios
  //     .get("https://sizeandfit.stailor.io/garment", {
  //       params: {
  //         preferenceData,
  //       },
  //     })
  //     .then((res) => {
  //       const productData = res.data;
  //       console.log("product data is", productData);
  //       navigate("recommendations", { state: { productData } });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  return (
    <>
      <Routes>
        <Route path="/" element={<Gender handleClick={handleClick} />} />
        <Route
          path="garment"
          element={<Garment handleClick={handleClick} preferenceData={preferenceData} />}
        />
        <Route
          path="color"
          element={<ColorPrefference handleClick={handleClick} preferenceData={preferenceData} />}
        />
        <Route
          path="price"
          element={<Price handleClick={handleClick} preferenceData={preferenceData} />}
        />
        {/* Not in use for the moment */}
        <Route path="loading" element={<LoadingScreen preferenceData={preferenceData} />} />
        <Route
          path="recommendations"
          element={
            <Recommendations preferenceData={preferenceData} isAuthenticated={isAuthenticated} />
          }
        />
      </Routes>
    </>
  );
};
export default GarmentPreference;
