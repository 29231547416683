import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useLanguage } from "../hooks/LanguageContext";

function ResetPassword() {
  const { translate } = useLanguage();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const { token } = useParams(); // extract token from the URL

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      return setMessage(translate("resetPassword.passwordsDoNotMatch"));
    }

    try {
      // const response = await axios.post(`http://localhost:5000/auth/reset/${token}`, {
      const response = await axios.post(`https://sizeandfit.stailor.io/auth/reset/${token}`, {
        password,
        confirmPassword,
      });
      setMessage(response.data);
      setTimeout(() => {
        window.location.replace("/login");
      }, 4000);
    } catch (error) {
      console.log(error);
      if (error.response.status === 400) setMessage(error.response.data);
      else setMessage(translate("resetPassword.error"));
    }
  };

  return (
    <div className="screen">
      <h1>{translate("resetPassword.title")}</h1>
      <form onSubmit={handleSubmit} className="form-auth">
        <label>
          {translate("resetPassword.newPassword")}
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </label>
        <label>
          {translate("resetPassword.confirmPassword")}
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </label>
        <button type="submit" className="btn btn-medium bgcolor-stailor">
          {translate("resetPassword.submit")}
        </button>
      </form>
      <p>{message}</p>
    </div>
  );
}

export default ResetPassword;
