import React from "react";
import { useLanguage } from "../hooks/LanguageContext";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";

function LanguageToggle() {
  const { currentLanguage, setCurrentLanguage } = useLanguage();

  const toggleLanguage = () => {
    // Toggle between Bulgarian ("bg") and English ("en")
    const newLanguage = currentLanguage === "bg" ? "en" : "bg";
    setCurrentLanguage(newLanguage);
  };

  return (
    <Typography onClick={toggleLanguage} sx={{ textDecoration: "underline", cursor: "pointer" }}>
      {currentLanguage === "bg" ? "Switch to English" : "Превключи на български"}
    </Typography>

    // // TODO: Fix the language logo size!
    // <>
    //   {currentLanguage === "bg" ? (
    //     <>
    //       <Typography
    //         onClick={toggleLanguage}
    //         sx={{ textDecoration: "underline", cursor: "pointer" }}
    //       >
    //         Switch to English
    //       </Typography>
    //       <img src="./united-kingdom.png" alt="UK flag" />
    //     </>
    //   ) : (
    //     <Typography
    //       onClick={toggleLanguage}
    //       sx={{ textDecoration: "underline", cursor: "pointer" }}
    //     >
    //       Превключи на български
    //     </Typography>
    //   )}
    // </>
  );
}

export default LanguageToggle;
