import { Link } from "react-router-dom";
import { useLanguage } from "../components/hooks/LanguageContext";
import Typography from "@mui/material/Typography";

const NotAuthenticated = () => {
  const { translate } = useLanguage();

  return (
    <div className="screen">
      <Typography variant="h4" gutterBottom>
        {translate("notAuthenticated.LoginToUnlock")}
      </Typography>
      <Link to="/login">
        <button className="btn btn-big btn-bgcolor-stailor">
          <Typography>{translate("common.loginText")}</Typography>
        </button>
      </Link>
      <Typography variant="h6" sx={{ marginTop: "25px" }} gutterBottom>
        {translate("common.noAccountMsg")}
      </Typography>
      <Typography gutterBottom>
        {translate("notAuthenticated.joinCommunityAccessFeatures")}
      </Typography>
      <ul>
        <li>
          <Typography>{translate("notAuthenticated.saveStylesProducts")}</Typography>
        </li>
        <li>
          <Typography>{translate("notAuthenticated.receivePersonalized")}</Typography>
        </li>
        <li>
          <Typography>{translate("notAuthenticated.andManyMore")}</Typography>
        </li>
      </ul>

      <Link to="/signup">
        <Typography sx={{ textDecoration: "underline" }}>
          {translate("common.signUpText")}
        </Typography>
      </Link>
    </div>
  );
};
export default NotAuthenticated;
