import { useState, useEffect } from "react";
import axios from "axios";

function useLikedItems(isAuthenticated, populate = false) {
  const [likedItems, setLikedItems] = useState({ products: [], styles: [] });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).token : "";
  const email = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).email : ""; // to be removed

  // Get the liked items from the user if there is authenticated user
  useEffect(() => {
    if (isAuthenticated) {
      setLoading(true);
      setError(null);

      axios
        // .get("http://localhost:5000/liked-items", {
        .get("https://sizeandfit.stailor.io/liked-items", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { populate },
        })
        .then((res) => {
          if (res.status === 200) {
            setLikedItems(res.data);
          } else {
            setError(`Failed to check liked status: ${res.status}`);
          }
        })
        .catch((error) => {
          setError(error.toString());
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [isAuthenticated, email]);

  return { likedItems, loading, error };
}

export default useLikedItems;
