import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, useNavigate } from "react-router-dom";
import CarouselElement from "../CarouselElement/CarouselElement";
import { useLanguage } from "../hooks/LanguageContext";
import "./HomePage.css";
import axios from "axios";
import Typography from "@mui/material/Typography";

/**
 * The Home Page component.
 * @param {Object} props - The component props.
 * @param {Array} props.topItems - An array of top items to display on the home page.
 * @param {Array} props.preferredStyles - An array of preferred clothing styles.
 */
const HomePage = ({ topItems, preferredStyles }) => {
  const navigate = useNavigate();

  const { translate, currentLanguage } = useLanguage();

  const handleClothesDiscoveryBannerClick = () => {
    navigate("/style-recommendation");
  };

  const handleDiscoveryBannerClick = () => {
    navigate("/add-style");
  };

  const handleAiChatBannerClick = () => {
    navigate("/aiassistant");
  };

  const handleDiscountBannerClick = () => {
    navigate("/coupons");
  };

  // TODO - TO BE TESTED!
  // const [matchingStyles, setMatchingStyles] = useState([]);

  // useEffect(() => {
  //   const handleGetMatchingStyles = async () => {
  //     try {
  //       const matchingStyles = [];

  //       for (const category of preferredStyles) {
  //         const response = await axios.post("http://localhost:8080/api/get-matching-styles", {
  //           preferredCategories: [category],
  //         });

  //         const stylesForCategory = response.data; // Get the style objects directly
  //         matchingStyles.push(...stylesForCategory);
  //       }

  //       setMatchingStyles(matchingStyles);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   if (preferredStyles) {
  //     handleGetMatchingStyles();
  //   }
  // }, [preferredStyles]);

  return (
    <div className="home-page">
      <img
        className="banner rounded"
        src={process.env.PUBLIC_URL + `/banner_styles_${currentLanguage}.png`}
        onClick={handleDiscoveryBannerClick}
        alt="banner"
      />

      {preferredStyles && (
        <div className="matching-styles">
          {/* TO BE ADDED AFTER IT IS DONE AND TESTED */}
          {/* <div className="top-text padding-top">
            <h2>Предпочитани стилове</h2>
          </div> */}
          <CarouselElement swipeable={false}>
            {/* {matchingStyles.map((style) => (
              <div key={style._id} className="image-holder">
                <Link to={`/style/${style._id}`}>
                  <div>
                    <img src={style.image} alt="Style" />
                  </div>
                </Link>
              </div>
            ))} */}
          </CarouselElement>
        </div>
      )}

      <div className="top-styles">
        <div className="top-text padding-top">
          <Typography variant="h5">{translate(`homepage.discoverStyles`)}</Typography>
        </div>

        <CarouselElement swipeable={false}>
          {topItems.styles.slice(0, 7).map((style) => (
            <div key={style._id} className="image-holder">
              <Link to={`/style/${style._id}`}>
                <div>
                  <img src={style.image} alt="Style" />
                </div>
              </Link>
            </div>
          ))}
        </CarouselElement>
      </div>

      <img
        className="banner rounded"
        src={process.env.PUBLIC_URL + `/banner_discount_${currentLanguage}.png`}
        onClick={handleDiscountBannerClick}
        alt="banner"
      />

      <div className="top-products">
        <div className="top-text">
          <Typography variant="h5" sx={{ marginTop: "30px" }}>
            {translate(`homepage.topProducts`)}
          </Typography>
        </div>
        <CarouselElement swipeable={false}>
          {topItems.products.slice(0, 7).map((product) => (
            <div key={product._id} className="image-holder">
              <Link to={`/product/${product._id}`}>
                <img src={product.imgUrl} alt="Product" />
              </Link>
            </div>
          ))}
        </CarouselElement>
      </div>

      <img
        className="banner rounded"
        src={process.env.PUBLIC_URL + `/banner_ai_${currentLanguage}.png`}
        onClick={handleAiChatBannerClick}
        alt="banner"
      />
      <img
        className="banner rounded"
        src={process.env.PUBLIC_URL + `/banner_discover_clothes_${currentLanguage}.png`}
        onClick={handleClothesDiscoveryBannerClick}
        alt="banner"
      />
    </div>
  );
};

export default HomePage;
