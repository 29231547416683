import Button from "@mui/material/Button";

const ButtonMUI = ({ text, variant, color, handleClick }) => {
  return (
    <Button variant={variant} color={color} onClick={handleClick}>
      {text}
    </Button>
  );
};

export default ButtonMUI;
