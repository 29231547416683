import { Carousel } from "react-responsive-carousel";
import "./CarouselElement.css";

const CarouselElements = (props) => {
  const showArrows = !props.showArrows ? props.showArrows : true;
  const autoPlay = props.autoPlay ? props.autoPlay : false;
  const showIndicators = props.showIndicators ? props.showIndicators : false;

  // Add style to the carousel arrows
  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(40% - 10px)",
    width: 30,
    height: 30,
    fontSize: "36px",
    cursor: "pointer",
    backgroundColor: "rgba(255, 255, 255, 0)",
    color: "#000",
    border: "none",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    WebkitAppearance: "none", // disable default button styles in iOS
  };

  // Add animation to the left arrow
  const arrowStylesLeftAnimation = {
    animation: "slide-left 2s infinite",
  };
  const arrowStyleLeft = { ...arrowStyles, ...arrowStylesLeftAnimation };
  // Add animation to the right arrow
  const arrowStylesRightAnimation = {
    animation: "slide-right 2s infinite",
  };
  const arrowStyleRight = { ...arrowStyles, ...arrowStylesRightAnimation };

  return (
    <Carousel
      axis={"horizontal"}
      verticalSwipe={"natural"}
      showArrows={showArrows}
      showThumbs={false}
      showStatus={false}
      autoPlay={autoPlay}
      swipeable={props.swipeable}
      showIndicators={showIndicators}
      emulateTouch={true}
      infiniteLoop
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            style={{ ...arrowStyleLeft, left: 5 }}
          >
            &#10096;
          </button>
        )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button
            type="button"
            onClick={onClickHandler}
            title={label}
            style={{ ...arrowStyleRight, right: 5 }}
          >
            &#10097;
          </button>
        )
      }
    >
      {props.children}
    </Carousel>
  );
};
export default CarouselElements;
