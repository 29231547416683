import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

const ShareButton = ({ sharingData }) => {
  const handleClick = async () => {
    const formatUrl = sharingData?.url.replace(/\s/g, "%20");

    const shareData = {
      title: "Stailor",
      text: sharingData?.text,
      url: formatUrl,
    };

    // Share must be triggered by "user activation"
    if (navigator.share) {
      try {
        await navigator.share(shareData);
        //   console.log("shared successfully");
      } catch (err) {
        console.log(`Error: ${err}`);
      }
    } else {
      alert("Share not supported on this browser, do it the old way.");
    }
  };

  return (
    <div className="btn-share" onClick={handleClick}>
      <ShareOutlinedIcon sx={{ fontSize: "30px", cursor: "pointer" }} />
    </div>
  );
};
export default ShareButton;
