import React, { useState, useEffect, useRef } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga4";

import NotAuthenticated from "./Screens/NotAuthenticated";
import Header from "./components/Header/Header";
import Login from "./components/Login/Login";
import SignUp from "./components/SignUp/SignUp";
import AppLandingPage from "./components/AppLandingPage/AppLandingPage";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Navbar from "./components/Navbar/Navbar";
import HomePage from "./components/HomePage/HomePage";
import GarmentPreference from "./components/GarmentPreference/GarmentPreference";
import ChatBot from "./components/ChatBot/ChatBot";
import Profile from "./components/Profile/Profile";
import UserSettings from "./components/UserSettings/UserSettings";
import Coupons from "./components/Coupons/Coupons";
import PersonalInfo from "./components/PersonalInfo/PersonalInfo";
import EditProfile from "./components/EditProfile/EditProfile";
import FeedProducts from "./components/FeedProducts/FeedProducts";
import Style from "./components/Style/Style";
import AddStyle from "./components/AddStyle/AddStyle";
import FeedStyle from "./components/FeedStyles/FeedStyles";
import Product from "./components/Product/Product";
import StoresScreen from "./components/StoresScreen/StoresScreen";
import "./app.css";
import AddService from "./components/AddService/AddService";
import ServicesScreen from "./components/ServicesScreen/ServicesScreen";
import UnapprovedStyles from "./Screens/UnapprovedStyles";

// const TRACKING_ID = process.env.GOOGLE_TRACKING_ID;
const TRACKING_ID = "G-SLP0FNSB7D";
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const initialUser = localStorage.getItem("user");
  const parsedUser = initialUser && initialUser !== "undefined" ? JSON.parse(initialUser) : {};
  const [user, setUser] = useState(parsedUser);
  // import from Platform.js
  const [topItems, setTopItems] = useState({ products: [], styles: [] });
  const [userData, setUserData] = useState(null);
  // const [userStyles, setUserStyles] = useState(); // not in use
  const [styles, setStyles] = useState();
  const [unapprovedStyles, setUnapprovedStyles] = useState([]);
  const [unapprovedStylesCount, setUnapprovedStylesCount] = useState(0);
  const location = useLocation();
  const mainContentRef = useRef();

  // Send pageview event to Google analytics
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: window.location.pathname,
  });

  useEffect(() => {
    const setVhVariable = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    // Set the variable at the start
    setVhVariable();

    // Set the variable whenever the window is resized
    window.addEventListener("resize", setVhVariable);

    // Clean up after the component unmounts
    return () => {
      window.removeEventListener("resize", setVhVariable);
    };
  }, []); // Empty dependency array so this effect runs once on mount and clean up on unmount

  useEffect(() => {
    const theUser = localStorage.getItem("user");

    if (!theUser) {
      const unAuthVisitorId = `visitor${Date.now()}`;
      localStorage.setItem("user", JSON.stringify({ id: unAuthVisitorId }));
    }

    if (theUser && !theUser.includes("undefined")) {
      setUser(JSON.parse(theUser));
    }
  }, []);

  useEffect(() => {
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  // Fetch user data if there is authenticated user
  useEffect(() => {
    const fetchPersonalInfo = async () => {
      try {
        // const response = await axios.get(`http://localhost:5000/get-personal-info`, {
        const response = await axios.get(`https://sizeandfit.stailor.io/get-personal-info`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setUserData(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
        if (error.response.status === 401) {
          // Remove the user session from local storage
          localStorage.removeItem("user");
          // Redirect to the home page
          window.location.href = "/";
        }
      }
    };

    // const fetchUserStyles = async () => {
    //   try {
    //     // const response = await axios.get(`http://localhost:5000/get-user-styles`, {
    //     const response = await axios.get(`https://sizeandfit.stailor.io/get-user-styles`, {
    //       headers: {
    //         Authorization: `Bearer ${user.token}`,
    //       },
    //     });
    //     setUserStyles(response.data);
    //   } catch (error) {
    //     console.error("Error fetching created styles: ", error);
    //     if (error.response.status === 401) {
    //       // Remove the user session from local storage
    //       localStorage.removeItem("user");
    //       // Redirect to the home page
    //       window.location.href = "/";
    //     }
    //   }
    // };

    if (user?.token) {
      fetchPersonalInfo();
      // fetchUserStyles();
    }
  }, [user?.token]);

  // Fetch top products
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // const response = await axios.get("http://localhost:5000/get-top-items");
        const response = await axios.get("https://sizeandfit.stailor.io/get-top-items");
        setTopItems(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchProducts();
  }, []);

  // Fetch styles
  useEffect(() => {
    axios
      // .get("http://localhost:5000/styles")
      .get("https://sizeandfit.stailor.io/styles")
      .then((res) => {
        if (res.status === 200) {
          const data = res.data;
          setStyles(data);
        } else console.log(res.status);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Fetch unapproved Styles (pending approval)
  useEffect(() => {
    const getUnapprovedStyles = async () => {
      try {
        // const response = await axios.get("http://localhost:5000/style/get-unapproved-styles", {
        const response = await axios.get(
          "https://sizeandfit.stailor.io/style/get-unapproved-styles",
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        if (response.status === 200) {
          setUnapprovedStyles(response.data.styles);
          setUnapprovedStylesCount(response.data.count);
        }
      } catch (err) {
        console.error("Error fetching unapproved styles:", err);
      }
    };

    if (user?.userType === "editor" || user?.userType === "admin") getUnapprovedStyles();
  }, []);

  let isAuthenticated = user?.token ? true : false;
  let isStartPage = location.pathname === "/" ? true : false;

  return (
    <div
      className="container-app"
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/body_bg.png)` }}
    >
      <Header
        isStartPage={isStartPage}
        isAuthenticated={isAuthenticated}
        user={user}
        unapprovedStylesCount={unapprovedStylesCount}
      />
      <div ref={mainContentRef} className="main-content">
        <Routes>
          <Route path="/" element={<AppLandingPage />} />
          <Route path="/login" element={isAuthenticated ? <Navigate to="/home" /> : <Login />} />
          <Route path="/signup" element={isAuthenticated ? <Navigate to="/home" /> : <SignUp />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route
            path="/home"
            element={
              <HomePage
                topItems={topItems}
                preferredStyles={userData?.personalInfo?.preferredStyles}
              />
            }
          />
          <Route
            path="/product/:productId"
            element={<Product isAuthenticated={isAuthenticated} />}
          />
          <Route
            path="/style-recommendation/*"
            element={<GarmentPreference isAuthenticated={isAuthenticated} />}
          />
          <Route path="/feed-products" element={<FeedProducts />} />
          <Route
            path="/feed-styles"
            element={<FeedStyle stylesData={styles} isAuthenticated={isAuthenticated} />}
          />
          <Route
            path="/style/:styleId"
            element={<Style user={user} isAuthenticated={isAuthenticated} />}
          />
          <Route path="/add-style" element={<AddStyle user={user} mode="add" />} />
          <Route path="/edit-style/:styleId" element={<AddStyle user={user} mode="edit" />} />
          <Route path="/aiassistant" element={<ChatBot user={user} />} />
          <Route path="/coupons" element={<Coupons />} />
          <Route path="/profile" element={<NotAuthenticated />} />
          <Route path="/:userName" element={<Profile isAuthenticated={isAuthenticated} />} />
          <Route
            path="/user-settings"
            element={<UserSettings user={user} isAuthenticated={isAuthenticated} />}
          />
          <Route path="/personal-info" element={<PersonalInfo user={user} userData={userData} />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/stores" element={<StoresScreen />} />
          <Route path="/add-service" element={<AddService />} />
          <Route path="/services" element={<ServicesScreen />} />
          <Route
            path="/moderator-unapproved-styles"
            element={<UnapprovedStyles styles={unapprovedStyles} userType={user?.userType} />}
          />
          <Route path="*" element={<Navigate to="/" />} /> {/* 404 Page */}
        </Routes>

        {!isStartPage && <Navbar />}
      </div>
    </div>
  );
};

export default App;
