import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import useLikedItems from "../hooks/useLikedItems";
import GarmentPreview from "../GarmentPreview/GarmentPreview";
import Loading from "../Loading/Loading";
import ImgThumbnail from "../ImgThumbnail/ImgThumbnail";
import { useLanguage } from "../hooks/LanguageContext";
import { trackButtonClick } from "../../functions/trackClicks";
import "./Recommendations.css";
import { Typography } from "@mui/material";

const Recommendations = ({ preferenceData, isAuthenticated }) => {
  const [productData, setProductData] = useState();
  const [aiLoading, setAiLoading] = useState(false);
  const [fabricInfo, setFabricInfo] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const navigate = useNavigate();
  const { translate } = useLanguage();

  const { likedItems } = useLikedItems(isAuthenticated);

  useEffect(() => {
    if (preferenceData.price !== "") {
      axios
        .get("https://sizeandfit.stailor.io/garment", {
          // .get("http://localhost:5000/dev/garment", {
          params: {
            preferenceData,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            setTimeout(() => {
              setProductData(data);
            }, 1000);
          } else console.log(res.status);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      navigate("/style-recommendation");
    }

    // try {
    //   const userString = localStorage.getItem("user");
    //   if (userString && !userString.includes("undefined")) {
    //     const user = JSON.parse(userString);
    //     setUserEmail(user.email);
    //   } else {
    //     // redirect to login page if there is no active user
    //     // window.location.href = "/login";
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
  }, [preferenceData, navigate]);

  let imgUrl;
  if (
    typeof productData !== "undefined" &&
    Array.isArray(productData) &&
    typeof productData[0].imgUrl !== "undefined"
  ) {
    imgUrl = checkImgUrl(productData[0].imgUrl, productData[0].storeUrl);
  }

  const askAI = (e) => {
    e.preventDefault();

    setAiLoading(true);
    axios
      .post(
        "https://sizeandfit.stailor.io/ai",
        {
          message: productData,
          prompt: "style",
        },
        {
          headers: {
            "Content-Type": "application/json",
            "x-user-email": userEmail,
          },
        }
      )
      .then((res) => {
        console.log(res);
        // ADD ERROR HANDLING -> if no data (res.status !== 200) to set it that something went wrong. Please try again later.
        console.log(res.data);
        setAiLoading(false);
        setFabricInfo(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const openProduct = () => {
    trackButtonClick(productData[0].url);
    window.open(productData[0].url, "_blank");
  };

  const garmentThumbnails = () => {
    if (Array.isArray(productData) && productData.length > 1) {
      return productData.slice(1).map((product, index) => {
        const imgUrl = checkImgUrl(product.imgUrl, product.storeUrl);
        return (
          <div key={index} onClick={() => handleThumbClick(index + 1)}>
            <ImgThumbnail img={imgUrl} />
          </div>
        );
      });
    }
  };
  const handleThumbClick = (index) => {
    const selectedItem = productData[index];
    const newProductDataArr = [...productData];
    newProductDataArr[index] = newProductDataArr[0];
    newProductDataArr[0] = selectedItem;
    setProductData(newProductDataArr);
  };

  if (productData && productData.productsNotFound === true)
    return (
      <div className="screen screen-recommendations">
        <Typography variant="h5">{translate("recommendations.noProductsFound")}</Typography>
        <Link to={"/style-recommendation"}>
          <button className="button_style">{translate("recommendations.tryAgain")}</button>
        </Link>
      </div>
    );

  if (productData && productData.productsNotFound !== true)
    return (
      <div className="screen screen-recommendations">
        <GarmentPreview
          previewObj="product"
          likedItems={likedItems}
          product={productData[0]}
          img={imgUrl}
        />
        <div className="container-btns">
          <button className="btn btn-medium bgcolor-stailor bold" onClick={openProduct}>
            {translate("common.view")}
          </button>
          {/* <button className="btn btn-medium" onClick={askAI}>
            STYLE
          </button> */}
        </div>

        <div>
          <Typography variant="h6" sx={{ marginTop: "20px" }}>
            {translate("recommendations.OtherRecommendations")}
          </Typography>
          {aiLoading && <Loading />}
          {fabricInfo && (
            <div>
              <h2>{translate("recommendations.styleResponse")}</h2>
              <p>{fabricInfo}</p>
            </div>
          )}
        </div>

        <div className="container-thumbnails">{garmentThumbnails()}</div>

        <Link to={"/style-recommendation"}>
          <button className="button_style">{translate("common.back")}</button>
        </Link>
      </div>
    );

  return (
    <div className="screen screen-recommendations">
      <Loading />
    </div>
  );
};

export default Recommendations;

const checkImgUrl = (imgUrl, storeUrl) => {
  // Check if img URL is a valid URL path
  const isImgUrlValid = checkIsValidUrl(imgUrl);
  // Concatinate img string if it is not a valid URL. (This is caused from the scraper data of some webstores)
  if (!isImgUrlValid) {
    if (storeUrl === "https://www.dizi.bg/") {
      const newImgUrl = storeUrl + imgUrl;
      return newImgUrl;
    }
  } else {
    return imgUrl;
  }
};

const checkIsValidUrl = (url) => {
  const regexUrl =
    /^(http|https):\/\/[\w\-]+(\.[\w\-]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?$/;
  const isValidUrl = regexUrl.test(url);
  return isValidUrl;
};
