import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./AddStyle.css";
import axios from "axios";
import { useLanguage } from "../hooks/LanguageContext";

const AddStyle = ({ user, mode }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [styleCategory, setStyleCategory] = useState("");
  const [products, setProducts] = useState([
    { name: "", url: "", imgUrl: "" },
    { name: "", url: "", imgUrl: "" },
  ]);
  const [errors, setErrors] = useState({});
  // const [successMessage, setSuccessMessage] = useState(""); // TODO: set message upon success

  const navigate = useNavigate();
  const { translate } = useLanguage();
  const { styleId } = useParams(); // Assuming you pass the style ID in the route params

  useEffect(() => {
    // Fetch the style to edit based on its ID if the component mode is "edit"
    const fetchStyleData = () => {
      axios
        // .get(`http://localhost:5000/style/${styleId}`, {
        .get(`https://sizeandfit.stailor.io/style/${styleId}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then((response) => {
          const { name, description, category, products } = response.data;
          setName(name);
          setDescription(description);
          setStyleCategory(category);
          setProducts(products);
        })
        .catch((error) => {
          console.error("Error fetching style:", error);
        });
    };

    // Check the mode to determine if you're adding or editing. If editing it fetch and populate form with existing data for editing
    if (mode === "edit" && styleId) {
      if (user.userType !== "admin" && user.userType !== "editor") navigate("/home"); // Redirects to home screen if the user is not an admin or editor
      fetchStyleData();
    }
  }, [mode, styleId, user, navigate]);

  const handleInputChange = (index, event) => {
    const values = [...products];
    values[index][event.target.name] = event.target.value;
    setProducts(values);
  };

  const handleAddFields = () => {
    setProducts([...products, { name: "", url: "", imgUrl: "" }]);
  };

  const handleRemoveFields = (index) => {
    const values = [...products];
    values.splice(index, 1);
    setProducts(values);
  };

  const handleFileChange = (event) => {
    setImage(event.target.files[0]);
  };

  const validateForm = () => {
    let formErrors = {};

    if (name.trim().length > 100) formErrors.name = "Името трябва да бъде по-малко от 100 символа";
    if (description.trim().length > 500)
      formErrors.description = "Описанието трябва да бъде по-малко от 500 символа";
    if (mode === "add") {
      if (!image) formErrors.image = "Необходимо е да добавите изображение на стила";
      if (image && image.size > 5000000)
        formErrors.image = "Размера на изображението не може да надвишава 5MB";
    }
    if (!styleCategory) formErrors.styleCategory = "Трябва да изберете категория за стила";

    products.forEach((product, index) => {
      if (product.name.trim().length > 100)
        formErrors[`name${index}`] = "Името на продукта трябва да бъде до 100 символа";
      if (!/^https?:\/\//i.test(product.url)) formErrors[`url${index}`] = "Невалиден URL";
      if (!/^https?:\/\//i.test(product.imgUrl))
        formErrors[`imgUrl${index}`] = "Невалиден URL на изображението";
    });

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    // Empty the form on submit to prevent double submit while waiting for response from the server
    // TODO: Added a loading indicator until the response is received!
    setName("");
    setDescription("");
    setImage(null);
    setStyleCategory("");
    setProducts([
      { name: "", url: "", imgUrl: "" },
      { name: "", url: "", imgUrl: "" },
    ]);
    setErrors({});

    const formData = new FormData();
    formData.append("name", name.trim());
    formData.append("description", description.trim());
    formData.append("styleCategory", styleCategory);
    formData.append("products", JSON.stringify(products));

    // // For debugging purposes only
    // for (let pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    // Check the mode to determine whether you're adding or editing
    if (mode === "add") {
      // Adding a new style
      formData.append("image", image);

      try {
        // Send the data to the server
        // const response = await axios.post("http://localhost:5000/addStyle", formData, {
        const response = await axios.post("https://sizeandfit.stailor.io/addStyle", formData, {
          headers: {
            Authorization: `Bearer ${user.token}`,
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.status === 201) {
          alert(translate("addStyle.successMessage"));
          navigate(`/${user?.userName}`, { state: { items: "created" } });
        } else alert(translate("addStyle.errorMessage"));
      } catch (error) {
        console.error("Error sending data:", error);
      }
    } else if (mode === "edit" && styleId) {
      // Editing an existing style
      try {
        const response = await axios.put(
          // `http://localhost:5000/style/edit/${styleId}`,
          `https://sizeandfit.stailor.io/style/edit/${styleId}`,
          {
            name: name,
            description: description,
            styleCategory: styleCategory,
            products: products,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        );

        if (response.status === 200) {
          alert(translate("addStyle.successMessage"));
          navigate(`/${user?.userName}`, { state: { items: "edited" } });
        } else {
          alert(translate("editStyle.errorMessage"));
        }
      } catch (error) {
        console.error("Error sending data:", error);
      }
    }
  };

  const linkedProducts = products.map((product, index) => {
    const productNumber = index + 1;

    return (
      <div key={index}>
        <div className="form-group">
          <label>
            {translate("addStyle.linkedProductName")} {productNumber}
          </label>
          <input
            type="text"
            name="name"
            value={product.name}
            onChange={(event) => handleInputChange(index, event)}
            required
          />
          {errors[`name${productNumber}`] && <p>{errors[`name${productNumber}`]}</p>}
        </div>
        <div className="form-group">
          <label>
            {translate("addStyle.linkedProductUrl")} {productNumber} (URL)
          </label>
          <input
            type="url"
            name="url"
            value={product.url}
            onChange={(event) => handleInputChange(index, event)}
            required
          />
          {errors[`url${productNumber}`] && <p>{errors[`url${productNumber}`]}</p>}
        </div>
        <div className="form-group">
          <label>
            {translate("addStyle.linkedProductImg")} {productNumber} (URL)
          </label>
          <input
            type="text"
            name="imgUrl"
            value={product.imgUrl}
            onChange={(event) => handleInputChange(index, event)}
            required
          />
          {errors[`productImage${productNumber}`] && (
            <p>{errors[`productImage${productNumber}`]}</p>
          )}
        </div>

        <button
          className="btn"
          type="button"
          onClick={() => handleRemoveFields(index)}
          disabled={products.length <= 2}
        >
          {translate("addStyle.remove") + " " + translate("addStyle.item") + " " + productNumber}
        </button>
      </div>
    );
  });

  return (
    <div className="screen screen-add-style">
      <form className="form-add-style" onSubmit={handleSubmit}>
        {mode === "add" && (
          <div className="form-group">
            <label>{translate("addStyle.styleImage")}</label>
            <input type="file" onChange={handleFileChange} required />
            {errors.image && <p>{errors.image}</p>}
          </div>
        )}

        <div className="form-group">
          <label>{translate("addStyle.styleName")}</label>
          <input
            type="text"
            value={name}
            name="name"
            onChange={(e) => setName(e.target.value)}
            required
          />
          {errors.name && <p>{errors.name}</p>}
        </div>

        <div className="form-group">
          <label>{translate("addStyle.styleDescription")}</label>
          <textarea
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
          {errors.description && <p>{errors.description}</p>}
        </div>

        <div className="form-group">
          <label>{translate("addStyle.styleCategory")}</label>
          <select value={styleCategory} onChange={(e) => setStyleCategory(e.target.value)} required>
            <option value="">--- {translate("common.stylesCategories.chooseCategory")} ---</option>
            <option value="casual">{translate("common.stylesCategories.casual")}</option>
            <option value="business-casual">
              {translate("common.stylesCategories.businessCasual")}
            </option>
            <option value="sportswear">{translate("common.stylesCategories.sportswear")}</option>
            <option value="athleisure">{translate("common.stylesCategories.athleisure")}</option>
            <option value="midnight">{translate("common.stylesCategories.midnight")}</option>
            <option value="resort">{translate("common.stylesCategories.resort")}</option>
          </select>
          {errors.styleCategory && <p>{errors.styleCategory}</p>}
        </div>

        <p>Свързани продукти</p>
        <div className="container-products">{linkedProducts}</div>

        <button className="btn btn-medium" type="button" onClick={handleAddFields}>
          {translate("addStyle.addProduct")}
        </button>

        <button className="btn btn-big btn-bgcolor-stailor" type="submit">
          {translate("addStyle.submit")}
        </button>
      </form>
    </div>
  );
};

export default AddStyle;
