import React, { createContext, useContext, useEffect, useState } from "react";
import enTranslations from "../../languages/en.json";
import bgTranslations from "../../languages/bg.json";
import { detectBrowserLanguage } from "../../functions/detectBrowserLanguage";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState("bg");
  const translations = {
    en: enTranslations,
    bg: bgTranslations,
  };

  // // Working! It sets the default language based on the user's browser language.
  // useEffect(() => {
  //   // Detect the user's location and set the language
  //   const userBrowserLanguage = detectBrowserLanguage();

  //   if (userBrowserLanguage) setCurrentLanguage(userBrowserLanguage);
  // }, []);

  const translate = (key) => {
    const keys = key.split("."); // Split the key by dots
    let translation = translations[currentLanguage];

    for (const nestedKey of keys) {
      if (translation && translation[nestedKey]) {
        translation = translation[nestedKey];
      } else {
        // If translation not found in current language, try the default language
        if (translations["en"] && translations["en"][nestedKey]) {
          translation = translations["en"][nestedKey];
        } else {
          console.warn(`Translation for key "${key}" not found in any language`);
          return key; // Return the key itself as a fallback
        }
      }
    }

    return translation;
  };

  return (
    <LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage, translate }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
