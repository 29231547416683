import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ButtonMUI from "../ButtonMUI/ButtonMUI";
import Modal from "../ModalWindow/ModalWindow";
import { useLanguage } from "../hooks/LanguageContext";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

const StyleModeratePanel = ({ view, isApproved, token }) => {
  const [showMessage, setShowMessage] = useState(false);
  const [actionType, setActionType] = useState("");
  const [deletePass, setDeletePass] = useState("");
  const { styleId } = useParams();
  const navigate = useNavigate();
  const { translate } = useLanguage();

  const openModal = (action) => {
    setShowMessage(true);
    setActionType(action);
  };

  const createModalContent = () => {
    switch (actionType) {
      case "approve":
        return (
          <>
            <Typography gutterBottom sx={{ p: 1 }}>
              {translate("styleModeratePanel.txtConfirmApproveStyle")}
            </Typography>
            <ButtonMUI
              text={translate("styleModeratePanel.btnModalApproveStyle")}
              variant="contained"
              color="success"
              handleClick={handleApproveStyle}
            />
          </>
        );
      case "delete":
        return (
          <>
            <Typography gutterBottom sx={{ p: 1 }}>
              {translate("styleModeratePanel.txtConfirmDeleteStyle")}
            </Typography>
            <TextField
              id="outlined-basic"
              label={translate("common.password")}
              variant="outlined"
              sx={{ margin: "10px 0" }}
              fullWidth
              required
              value={deletePass}
              onChange={(e) => {
                setDeletePass(e.target.value);
              }}
            />
            <ButtonMUI
              text={translate("styleModeratePanel.btnModalDeleteStyle")}
              variant="contained"
              color="error"
              handleClick={handleDeleteStyle}
            />
          </>
        );
      default:
        return <Typography>{translate("styleModeratePanel.txtChooseAction")}</Typography>;
    }
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
    setDeletePass("");
  };

  const handleDeleteStyle = async () => {
    if (deletePass !== "") {
      try {
        // Send a DELETE request to the API endpoint
        // await axios.delete(`http://localhost:5000/style/delete-style/${styleId}`, {
        await axios.delete(`https://sizeandfit.stailor.io/style/delete-style/${styleId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            deletePass: deletePass,
          },
        });
        // Handle success or provide feedback to the admin
        alert("Style deleted successfully");
        window.location.href = "/feed-styles";
      } catch (error) {
        // Handle errors or show error messages
        console.error("Error deleting style:", error);
        if (error) alert(error.response.data.message);
      }
    } else {
      alert(translate("styleModeratePanel.errorMsgPasswordRequired"));
    }
  };

  const handleApproveStyle = async () => {
    try {
      const response = await axios.post(
        // `http://localhost:5000/style/approve/${styleId}`,
        `https://sizeandfit.stailor.io/style/approve/${styleId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        alert("Style approved successfully");
        window.location.href = "/feed-styles";
      }
    } catch (error) {
      console.error("Error approving style:", error);
      if (error) alert(error.response.data.message);
    }
  };

  return (
    <Box
      sx={{
        margin: "20px auto 0",
        p: 1,
        width: "100%",
        maxWidth: 360,
        bgcolor: "#F2E1DE",
        borderRadius: "4px",
      }}
    >
      <Box sx={{ my: 3, mx: 2 }}>
        <Typography gutterBottom variant="h6" component="div">
          {translate("styleModeratePanel.moderatePanel")}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {translate("styleModeratePanel.textAcceptEditDelete")}
        </Typography>
      </Box>
      <Divider variant="middle" />
      <Box sx={{ m: 2 }}>
        <Stack direction="row" spacing={1} sx={{ justifyContent: "center" }}>
          {!isApproved && (
            <ButtonMUI
              text={translate("styleModeratePanel.btnApprove")}
              variant="contained"
              color="success"
              handleClick={() => {
                openModal("approve");
              }}
            />
          )}
          <ButtonMUI
            text={translate("styleModeratePanel.btnEdit")}
            variant="outlined"
            color="warning"
            handleClick={() => {
              navigate(`/edit-style/${styleId}`);
            }}
          />
          {view === "admin" && (
            <ButtonMUI
              text={translate("styleModeratePanel.btnDelete")}
              variant="contained"
              color="error"
              handleClick={() => {
                openModal("delete");
              }}
            />
          )}
        </Stack>
      </Box>
      {showMessage && <Modal onClose={handleCloseMessage}>{createModalContent()}</Modal>}
    </Box>
  );
};

export default StyleModeratePanel;
