import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Typography from "@mui/material/Typography";

import { useLanguage } from "../hooks/LanguageContext";
import Loading from "../Loading/Loading";

const ProductFeed = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [page, setPage] = useState(1);
  const perPage = 30;
  const { translate } = useLanguage();

  useEffect(() => {
    loadProducts();
  }, []); // Load initial products on component mount

  const loadProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        //   `http://localhost:5000/products/get-products-feed?limit=${perPage}`
        `https://sizeandfit.stailor.io/products/get-products-feed?limit=${perPage}`
      );

      if (response.data.length > 0) {
        // Filter out duplicate products
        const newProducts = response.data.filter((product) => {
          return !products.some((loadedProduct) => loadedProduct._id === product._id);
        });

        // Filter out products with 404 image errors
        const filteredProducts = await filter404Images(newProducts);

        setProducts((prevProducts) => [...prevProducts, ...filteredProducts]);
        // setPage((prevPage) => prevPage + 1);
      } else {
        // Handle the case where no more products are available
        console.log("No more products available.");
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLoadMore = () => {
    loadProducts();
  };

  // Function to filter out products with 404 image errors
  const filter404Images = async (productsToFilter) => {
    const filteredProducts = await Promise.all(
      productsToFilter.map(async (product) => {
        const img = new Image();
        img.src = product.imgUrl;

        return new Promise((resolve) => {
          img.onload = () => {
            resolve(product);
          };

          img.onerror = () => {
            // console.log(`Image not found for product with ID ${product._id}`);
            resolve(null); // Resolve with null for products with 404 image errors
          };
        });
      })
    );

    return filteredProducts.filter((product) => product !== null);
  };

  return (
    <div className="screen screen-styles">
      <div className="top-text">
        <Typography variant="h5">{translate("feedProducts.mainTextTop")}</Typography>
      </div>
      <ImageList sx={{ width: "inherit" }} cols={3}>
        {products.map((product) => (
          <Link to={`/product/${product._id}`} key={product._id}>
            <ImageListItem>
              <img src={product.imgUrl} alt="product thumbnail" loading="lazy" />
            </ImageListItem>
          </Link>
        ))}
      </ImageList>

      {/* {loading && <p>Loading...</p>} */}
      {loading && <Loading />}

      {!loading && (
        <button className="btn btn-medium" onClick={handleLoadMore}>
          {translate("common.seeMore")}
        </button>
      )}
    </div>
  );
};

export default ProductFeed;
