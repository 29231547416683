import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import useLikedItems from "../hooks/useLikedItems";
import GarmentPreview from "../GarmentPreview/GarmentPreview";
import Loading from "../Loading/Loading";
import { useLanguage } from "../hooks/LanguageContext";
import CarouselElement from "../CarouselElement/CarouselElement";
import ProfileBar from "../ProfileBar/ProfileBar";
import StyleModeratePanel from "../StyleModeratePanel/StyleModeratePanel";
import { trackButtonClick } from "../../functions/trackClicks";
import Alert from "@mui/material/Alert";
import "./Style.css";

import Coupon from "../Coupon/Coupon";

const Style = ({ isAuthenticated, user }) => {
  const { styleId } = useParams();
  const [styleData, setStyleData] = useState(null); // Initialize with null
  const { likedItems } = useLikedItems(isAuthenticated);
  const { translate } = useLanguage();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStyleData = async () => {
      try {
        // Fetch the style data
        // const styleResponse = await axios.get(`http://localhost:5000/style/${styleId}`, {
        const styleResponse = await axios.get(`https://sizeandfit.stailor.io/style/${styleId}`, {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        });

        if (styleResponse.status === 200) {
          const styleData = styleResponse.data;
          setStyleData(styleData);
        } else {
          console.log("Failed to fetch style data.");
        }
      } catch (err) {
        if (err.response.status === 404) {
          alert(translate("screenStyle.messageStyleNotFound"));
          window.location.href = "/feed-styles";
        } else {
          // console.error("Error:", err);
          alert(translate("common.errorMessages.error500"));
          window.location.href = "/feed-styles";
        }
      }
    };

    fetchStyleData();
  }, [styleId]);

  const openProduct = (url) => {
    window.open(url, "_blank");
  };

  const exploreStyle = (e) => {
    const arrowNextElement = document.querySelector("button[title^='next slide']");
    arrowNextElement.click();
  };

  // Conditional rendering: Show loading indicator while styleData is null
  if (styleData === null) {
    return (
      <div className="screen screen-recommendations">
        <Loading />
      </div>
    );
  }

  // Once styleData is available, render the style details
  return (
    <div className="screen screen-style">
      {!styleData?.isApproved && (
        <Alert severity="warning" sx={{ marginBottom: "10px" }}>
          Стилът изчаква одобрение. След като бъде одобрен, ще е видим за всички потребители.
        </Alert>
      )}
      <ProfileBar
        userName={styleData.createdByUser?.userName}
        userPic={styleData.createdByUser?.picture}
      />
      <CarouselElement swipeable={false}>
        <GarmentPreview
          previewObj="style"
          likedItems={likedItems}
          product={styleData}
          img={styleData.image}
          hasDescription={true}
        >
          <div className="container-btns">
            <button
              className="btn btn-medium"
              onClick={() => {
                navigate(-1);
              }}
            >
              {translate("common.back")}
            </button>
            <button className="btn btn-medium" onClick={exploreStyle}>
              {translate("common.explore")}
            </button>
          </div>
        </GarmentPreview>
        {styleData.products.map((product, index) => (
          <div key={index}>
            <GarmentPreview
              previewObj="style"
              likedItems={likedItems}
              product={product}
              img={product.imgUrl}
              isLikable={false}
            />
            <div className="container-btns">
              <button
                className="btn btn-medium bgcolor-stailor bold"
                onClick={() => {
                  trackButtonClick(product.url);
                  openProduct(product.url);
                }}
              >
                {translate("common.view")}
              </button>
            </div>
          </div>
        ))}
      </CarouselElement>
      <Coupon />
      {user.userType === "admin" && (
        <StyleModeratePanel view="admin" isApproved={styleData?.isApproved} token={user?.token} />
      )}
      {user.userType === "editor" && (
        <StyleModeratePanel view="editor" isApproved={styleData?.isApproved} token={user?.token} />
      )}
    </div>
  );
};

export default Style;
