import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import SlidingMenu from "../SlidingMenu/SlidingMenu";
import "./Header.css";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";

const Header = ({ isStartPage, isAuthenticated, user, unapprovedStylesCount }) => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/home");
  };

  const linkTo = user?.userName ? user.userName : "profile";

  const renderAvatarInitials = () => {
    let initialsString;
    if (user?.firstName) {
      initialsString = user?.firstName?.split("")[0];
      if (user?.lastName) initialsString = initialsString + user?.lastName?.split("")[0];
    }
    return initialsString;
  };

  return (
    <div
      className="header"
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/header_bg.png)` }}
    >
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={3}>
          {!isStartPage && (
            <Link to={`/${linkTo}`}>
              {user?.picture ? (
                <Avatar src={user?.picture} alt="avatar" />
              ) : (
                <Avatar alt="avatar" sx={{ bgcolor: "#EF492A" }}>
                  {user?.firstName && renderAvatarInitials()}
                </Avatar>
              )}
            </Link>
          )}
        </Grid>
        <Grid item xs={6}>
          <img
            className="header-logo"
            src={process.env.PUBLIC_URL + "/logo.png"}
            alt="Stailor logo"
            onClick={handleLogoClick}
          />
        </Grid>
        <Grid item xs={3} container justifyContent="flex-end">
          {!isStartPage && (
            <SlidingMenu
              userIsAuthenticated={isAuthenticated}
              profileLink={linkTo}
              userType={user?.userType}
              unapprovedStylesCount={unapprovedStylesCount}
            />
          )}
        </Grid>
      </Grid>
      {/* {!isStartPage && (
        <Link to={`/${linkTo}`}>
          {user?.picture ? (
            <Avatar src={user?.picture} alt="avatar" />
          ) : (
            <Avatar alt="avatar" sx={{ bgcolor: "#EF492A" }}>
              {user?.firstName && renderAvatarInitials()}
            </Avatar>
          )}
        </Link>
      )}
      <img
        className="header-logo"
        src={process.env.PUBLIC_URL + "/logo.png"}
        alt="Stailor logo"
        onClick={handleLogoClick}
      />
      <SlidingMenu userIsAuthenticated={isAuthenticated} profileLink={linkTo} />
      {!isStartPage && <SlidingMenu userIsAuthenticated={isAuthenticated} profileLink={linkTo} />} */}
    </div>
  );
};

export default Header;
