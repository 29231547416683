import React, { useState } from "react";
import "./EditProfile.css";
import PfpHolder from "../PfpHolder/PfpHolder";
import { useLanguage } from "../hooks/LanguageContext";

const EditProfile = () => {
  const { translate } = useLanguage();

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div className="screen screen-edit-profile">
      <PfpHolder />
      <div className="form-edit-profile">
        <p className="txt-label">{translate.editProfile.usernameLabel}</p>
        <div className="username-box">
          <input
            type="text"
            id="username-input"
            value={username}
            onChange={handleUsernameChange}
            placeholder={translate.editProfile.usernameLabel}
          />
        </div>
        <p className="txt-label">{translate.editProfile.emailLabel}</p>
        <div className="username-box">
          <input
            type="text"
            id="email-input"
            value={email}
            onChange={handleEmailChange}
            placeholder={translate.editProfile.emailLabel}
          />
        </div>
        <p className="txt-label">{translate.editProfile.phoneLabel}</p>
        <div className="username-box">
          <input
            type="text"
            id="phone-input"
            value={phone}
            onChange={handlePhoneChange}
            placeholder={translate.editProfile.phoneLabel}
          />
        </div>
        <p className="txt-label">{translate.editProfile.passwordLabel}</p>
        <div className="username-box">
          <input
            type="password"
            id="password-input"
            value={password}
            onChange={handlePasswordChange}
            placeholder={translate.editProfile.passwordLabel}
          />
        </div>
        <button className="btn btn-medium btn-hover">
          {translate.editProfile.updateButton}
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
