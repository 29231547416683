import "./AppLandingPage.css";
import { Link } from "react-router-dom";
import { useLanguage } from "../hooks/LanguageContext";
import CarouselElements from "../CarouselElement/CarouselElement";

const AppLandingPage = () => {
  const { translate } = useLanguage();

  return (
    <div className="landing-page">
      <Link to="/home">
        <CarouselElements showArrows={false} autoPlay={true} showIndicators={true}>
          <img
            src={process.env.PUBLIC_URL + "/vp_items_discovery.png"}
            alt="Discover styles and products"
          />
          <img
            src={process.env.PUBLIC_URL + "/vp_personal_recommendations.png"}
            alt="Get personal recommendations"
          />
          <img src={process.env.PUBLIC_URL + "./vp_discounts.png"} alt="Get discounts" />
        </CarouselElements>
      </Link>
      {/* <h2>{translate("appLandingPage.introText")}</h2> */}
      <Link to="/home">
        <button>{translate("appLandingPage.startButton")}</button>
      </Link>
    </div>
  );
};

export default AppLandingPage;
