import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ServicesScreen from "../ServicesScreen/ServicesScreen";
import { useLanguage } from "../hooks/LanguageContext";
import "./Profile.css";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Avatar from "@mui/material/Avatar";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import CheckroomOutlinedIcon from "@mui/icons-material/CheckroomOutlined";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import TryOutlinedIcon from "@mui/icons-material/TryOutlined";
import ShareButton from "../ShareButton/ShareButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Modal from "../ModalWindow/ModalWindow";
import { Button } from "@mui/material";

const Profile = ({ isAuthenticated }) => {
  const { userName } = useParams();
  const navigate = useNavigate();
  const { translate } = useLanguage();

  // Component state variables
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState("created");
  const [initialUser, setInitialUser] = useState(null);
  const [profileData, setProfileData] = useState();
  const [fetchedUser, setFetchedUser] = useState(null);
  const [userServices, setUserServices] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const instructionsStyle = {
    textAlign: "center",
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
    lineHeight: "1.5",
    whiteSpace: "pre-line",
  };

  const buttonStyle = {
    marginTop: "20px",
    backgroundColor: "#EF492A",
    color: "white",
  };

  const handleInfoClick = () => {
    // You can customize the modal content based on your needs
    const infoContent = (
      <div style={instructionsStyle}>
        <Typography variant="h5" fontWeight="bold">
          Как да създадете своя стил
        </Typography>
        {`
        Изберете своя снимка, на която да покажете последните си придобивки и колко добре Ви стоят! Намерете всеки от артикулите в онлайн магазините и влезте отново в Stailor, където:
        1. Отивате на менюто в горния десен ъгъл
        2. Натискате Профил // Звезда // Създаване на стил
        3. Качвате снимката с вашия тоалет от бутона Избор на файл
        4. Поставяте заглавие на стила си и кратко описание
        5. Копирате и поставяте  линковете на артикулите (с десен бутон на мишката + Копирай адреса на връзката) и на снимките им  (с десен бутон на мишката + Копирай изображението)
        6. Натискате Изпрати и вашият стил е вече готов да вдъхновява
      `}
        <Link to="/add-style" style={{ textDecoration: "none" }}>
          <Button variant="contained" style={buttonStyle}>
            Направи стил
          </Button>
        </Link>
      </div>
    );

    setModalContent(infoContent);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const initialUser = localStorage.getItem("user");

    if (initialUser) {
      setInitialUser(JSON.parse(initialUser));
    }
  }, []);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        // Fetch the profile data for this username
        // const response = await axios.get(`http://localhost:5000/profile/get-profile/${userName}`, {
        const response = await axios.get(
          `https://sizeandfit.stailor.io/profile/get-profile/${userName}`,
          {
            headers: {
              Authorization: `Bearer ${initialUser.token}`,
            },
          }
        );

        if (response.status === 200) {
          const profileData = response.data;
          setProfileData(profileData);
          setFetchedUser(profileData.user);

          const userId = profileData.user._id;

          // Fetch services by user's _id
          const servicesResponse = await axios.get(
            // `http://localhost:5000/profile/get-services/${userId}`
            `https://sizeandfit.stailor.io/profile/get-services/${userId}`
          );

          if (servicesResponse.status === 200) {
            const userServicesData = servicesResponse.data;
            setUserServices(userServicesData);
          } else {
            console.log("Failed to fetch user's services.");
          }
        } else {
          console.log("Failed to fetch profile information.");
        }
      } catch (error) {
        if (error.response.status === 404) {
          // Redirect to the home page if the user is not found
          alert("User not found!");
          window.location.href = "/home";
        } else {
          console.error("Error:", error);
        }
      }
    };

    if (initialUser) fetchProfileData();
  }, [initialUser, userName]);

  useEffect(() => {
    // Check if this is still necessary
    if (profileData && profileData !== "") {
      if (profileData.isPublicProfile) setItems("created");
      setIsLoading(false);
    }
  }, [profileData]);

  const handleEditClick = () => {
    navigate("/personal-info");
  };

  const handleCreatedStylesClick = () => {
    setItems("created");
  };

  const handleStylesClick = () => {
    setItems("styles");
  };

  const handleProductsClick = () => {
    setItems("products");
  };

  const handleServicesClick = () => {
    setItems("services");
  };

  const renderSectionButtons = () => {
    // Check if it is public profile (not personal) or own profile
    if (profileData?.isPublicProfile) {
      // Check if the user is "creator" (stylist); Stylist's profile has different public view
      if (fetchedUser.userType !== "user") {
        return (
          <>
            <button className="btn btn-big" onClick={handleCreatedStylesClick}>
              <StarBorderOutlinedIcon fontSize="large" />
            </button>
            <button className="btn btn-big" onClick={handleServicesClick}>
              <TryOutlinedIcon fontSize="large" />
            </button>
          </>
        );
      } else {
        return (
          <>
            {/* <button className="btn btn-big" onClick={handleProductsClick}>
              <FavoriteBorderOutlinedIcon fontSize="large" />
            </button>
            <button className="btn btn-big" onClick={handleStylesClick}>
              <CheckroomOutlinedIcon fontSize="large" />
            </button> */}
            <button className="btn btn-big" onClick={handleCreatedStylesClick}>
              <StarBorderOutlinedIcon fontSize="large" />
            </button>
          </>
        );
      }
    } else {
      return (
        <>
          <button className="btn btn-big" onClick={handleCreatedStylesClick}>
            <StarBorderOutlinedIcon fontSize="large" />
          </button>
          <button className="btn btn-big" onClick={handleProductsClick}>
            <FavoriteBorderOutlinedIcon fontSize="large" />
          </button>
          <button className="btn btn-big" onClick={handleStylesClick}>
            <CheckroomOutlinedIcon fontSize="large" />
          </button>
          {fetchedUser?.userType !== "user" && (
            <>
              <button className="btn btn-big" onClick={handleServicesClick}>
                <TryOutlinedIcon fontSize="large" />
              </button>
            </>
          )}
        </>
      );
    }
  };

  const thumbnails = () => {
    let thumbnails;
    if (items === "created") {
      // Returns the styles created from the user. If own profile - all created are visible. If public - only the approved styles are visible. (Controlled on the server.)
      const createdStyles = profileData.createdStyles;
      if (createdStyles?.length > 0) {
        thumbnails = createdStyles?.map((style) => {
          const img = style.image;
          return (
            <Link key={style._id} to={`/style/${style._id}`}>
              <ImageListItem>
                {!style.isApproved && (
                  <Box
                    sx={{
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "#fff",
                      zIndex: 5,
                    }}
                  >
                    <Typography>{translate("profile.pendingApproval")}</Typography>
                  </Box>
                )}
                <img src={img} alt="created style thumbnail" loading="lazy" />
              </ImageListItem>
            </Link>
          );
        });
      }
      <Link to="/add-style">
        <button className="btn btn-medium">{translate("profile.addStyle")} 123</button>
      </Link>;
    } else if (items === "products") {
      thumbnails = profileData.likedProducts?.map((product, index) => (
        <Link
          key={index}
          to={`/product/${product._id}`}
          state={{ productData: product, isAuthenticated: isAuthenticated }}
        >
          <ImageListItem>
            <img src={product.imgUrl} alt="garment thumbnail" loading="lazy" />
          </ImageListItem>
        </Link>
      ));
    } else if (items === "styles") {
      thumbnails = profileData.likedStyles?.map((style, index) => {
        const img = style.image;
        return (
          <Link key={index} to={`/style/${style._id}`}>
            <ImageListItem>
              <img src={img} alt="style thumbnail" loading="lazy" />
            </ImageListItem>
          </Link>
        );
      });
    }

    return thumbnails;
  };

  const renderItemsButton = (itemType) => {
    if (itemType === "products") {
      return (
        <Link to="/style-recommendation">
          <button className="btn btn-medium">{translate("profile.explore")}</button>
        </Link>
      );
    } else if (itemType === "styles") {
      return (
        <Link to="/feed-styles">
          <button className="btn btn-medium">{translate("profile.explore")}</button>
        </Link>
      );
    } else if (itemType === "created") {
      return (
        <Link to="/add-style">
          <button className="btn btn-medium">{translate("profile.addStyle")}</button>
        </Link>
      );
    } else if (itemType === "services") {
      return (
        <Link to="/add-service">
          <button className="btn btn-medium">{translate("profile.addService")}</button>
        </Link>
      );
    }
    return "";
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="screen screen-profile">
      <Grid container>
        <Grid xs={4.5}>
          {fetchedUser?.picture ? (
            <Avatar src={fetchedUser?.picture} alt="avatar" sx={{ width: 120, height: 120 }} />
          ) : (
            <Avatar alt="avatar" sx={{ bgcolor: "#EF492A", width: 120, height: 120, fontSize: 48 }}>
              {fetchedUser?.givenName && fetchedUser?.givenName.split("")[0]}
              {fetchedUser?.familyName && fetchedUser?.familyName.split("")[0]}
            </Avatar>
          )}
        </Grid>
        <Grid
          container
          xs={6.5}
          display="flex"
          direction="column"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography variant="h6">{fetchedUser?.userName}</Typography>
          <Box
            sx={{
              width: 85,
              height: 20,
              backgroundColor: "#EF492A",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          />
        </Grid>
        <Grid xs={1} display="flex" direction="column" alignItems="center">
          {!profileData.isPublicProfile && (
            <div className="btn-edit-profile" onClick={handleEditClick}>
              <EditOutlinedIcon fontSize="medium" />
            </div>
          )}
        </Grid>
        <Grid container xs={11} display="flex" alignItems="flex-start">
          <Typography variant="h6">
            {fetchedUser?.givenName} {fetchedUser?.familyName && fetchedUser?.familyName}
          </Typography>
        </Grid>
        <Grid xs={1}>
          <ShareButton sharingData={{ url: window.location.href }} />
        </Grid>
        <Grid container xs={11} display="flex" alignItems="flex-start">
          <Typography align="left" paragraph sx={{ whiteSpace: "pre-line" }}>
            {fetchedUser?.personalInfo.bio}
          </Typography>
        </Grid>
      </Grid>
      <div className="container-buttons">{renderSectionButtons()}</div>
      <div className="top-text">
        <Typography variant="h6" sx={{ margin: "5px 0" }}>
          {items === "products" || items === "styles" ? translate("common.likedPlural") : ""}{" "}
          {translate(`common.${items}`)} {items === "created" && translate("common.styles")}
        </Typography>
        {["created"].includes(items) && (
          <InfoOutlinedIcon
            fontSize="large"
            style={{ marginLeft: "8px", cursor: "pointer" }}
            onClick={handleInfoClick}
          />
        )}
      </div>

      {isModalOpen && <Modal onClose={() => setIsModalOpen(false)}>{modalContent}</Modal>}

      {items === "services" && (
        <div className="services-section">
          {userServices && userServices.length > 0 ? (
            <ServicesScreen userServices={userServices} />
          ) : (
            <Typography>{translate("profile.noAddedServices")}</Typography>
          )}
        </div>
      )}
      {items !== "services" && (
        <ImageList sx={{ width: "inherit" }} cols={3}>
          {thumbnails()}
        </ImageList>
      )}
      {!profileData.isPublicProfile && renderItemsButton(items)}
    </div>
  );
};

export default Profile;
