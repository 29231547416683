import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import useFetch from "../hooks/useFetch";
import { useLanguage } from "../hooks/LanguageContext";
import Loading from "../Loading/Loading";
import { replaceLinks } from "../../functions/replaceLinks";

import "./SignUp.css";
import Typography from "@mui/material/Typography";

// https://developers.google.com/identity/gsi/web/reference/js-reference

const SignUp = () => {
  const [givenName, setGivenName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [expanded, setExpanded] = useState(false);

  const { translate } = useLanguage();

  const infoTextWithLinks = replaceLinks(translate("screenSignUp.txtInfo"));

  // const { handleGoogle, loading, error } = useFetch("http://localhost:5000/auth/signupGoogle");
  const { handleGoogle, loading, error } = useFetch(
    "https://sizeandfit.stailor.io/auth/signupGoogle"
  );

  useEffect(() => {
    /* global google */
    if (window.google) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });

      google.accounts.id.renderButton(document.getElementById("signUpDiv"), {
        theme: "filled_black", // filled_black / outline
        text: "signup_with",
        size: "large",
        width: 354,
        // locale: lang, // Use the selected language for the Google button
      });
    }
  }, [handleGoogle]);

  const toggleForm = () => {
    setExpanded(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert(translate("screenSignUp.passwordDoNotMatch"));
      return;
    }

    try {
      const data = {
        email: email,
        givenName: givenName,
        pass: password,
      };

      // await axios.post(`http://localhost:5000/auth/signup`, { data });
      await axios.post(`https://sizeandfit.stailor.io/auth/signup`, { data });
      alert(translate("screenSignUp.successfulRegistration"));
      window.location.href = "/login";
    } catch (err) {
      console.log(err);
      if (err.request.status === 400) {
        let parsedErrMessage = JSON.parse(err.request.response);
        alert(parsedErrMessage.message);
      }
    }
  };

  const renderFormFields = () => {
    if (expanded) {
      return (
        <>
          <label>
            <Typography>{translate("common.email")}</Typography>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={translate("common.email")}
              required
            />
          </label>
          <label>
            <Typography>{translate("screenSignUp.givenName")}</Typography>
            <input
              type="text"
              value={givenName}
              onChange={(e) => setGivenName(e.target.value)}
              placeholder={translate("screenSignUp.givenName")}
              required
            />
          </label>
          <label>
            <Typography> {translate("common.password")}</Typography>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={translate("common.password")}
              required
            />
          </label>
          <label>
            <Typography>{translate("screenSignUp.confirmPassword")}</Typography>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={translate("screenSignUp.confirmPassword")}
            />
          </label>
          <button type="submit" className="btn btn-big btn-bgcolor-stailor">
            {translate("screenSignUp.registerBtn")}
          </button>
        </>
      );
    } else {
      return (
        <label onClick={toggleForm}>
          {translate("common.email")}
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={translate("common.email")}
            required
          />
        </label>
      );
    }
  };

  return (
    <div className="screen screen-signup">
      <img className="image-sign" src="./signPhoto.png" alt="Login" />
      <Typography variant="h4" gutterBottom>
        {translate("screenSignUp.register")}
      </Typography>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {loading ? (
        <Loading />
      ) : (
        <>
          <form onSubmit={handleSubmit} className="form-auth">
            {renderFormFields()}
          </form>
          <Typography variant="h5" sx={{ margin: "20px 0" }}>
            {translate("screenSignUp.registerWithGoogle")}
          </Typography>
          <div
            id="signUpDiv"
            className="container-btn-authentication"
            data-text="signup_with"
          ></div>
        </>
      )}
      <div className="information">
        <img src="./info_icon.png" alt="info icon" />
        <Typography variant="caption" align="justify" sx={{ fontStyle: "italic" }}>
          {infoTextWithLinks}
        </Typography>
      </div>
      <Typography sx={{ marginTop: "20px" }}>
        {translate("screenSignUp.alreadyHaveAccount")}
      </Typography>
      <Link to="/login">
        <Typography sx={{ textDecoration: "underline" }}>
          {translate("screenSignUp.loginFromHere")}
        </Typography>
      </Link>
    </div>
  );
};
export default SignUp;
