import React from "react";
import "./LoginButton.css";
import { useLanguage } from "../hooks/LanguageContext";
import Typography from "@mui/material/Typography";

const LoginButton = () => {
  const { translate } = useLanguage();

  const handleLogin = () => {
    // Redirect to the home page
    window.location.href = "/login";
  };

  return (
    <button className="btn btn-medium btn-login" onClick={handleLogin}>
      <Typography>{translate("common.loginText")}</Typography>
    </button>
  );
};

export default LoginButton;
