import React from "react";
import Avatar from "@mui/material/Avatar";

const PfpHolder = ({ pfpUrl }) => {
  return (
    <>
      {pfpUrl ? (
        <Avatar src={pfpUrl} alt="avatar" sx={{ width: 120, height: 120 }} />
      ) : (
        <Avatar alt="avatar" sx={{ bgcolor: "#EF492A", width: 120, height: 120, fontSize: 48 }}>
          {/* {fetchedUser?.givenName.split("")[0]}
              {fetchedUser?.familyName.split("")[0]} */}
        </Avatar>
      )}
    </>
  );
};

export default PfpHolder;
