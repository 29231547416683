import React, { useState, useRef } from "react";
import "./Coupons.css";
import Modal from "../ModalWindow/ModalWindow";
import { useLanguage } from "../hooks/LanguageContext";
import { trackButtonClick } from "../../functions/trackClicks";
import Typography from "@mui/material/Typography";

const Coupons = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [couponData, setCouponData] = useState({});
  const { translate } = useLanguage();

  const handleClick = (data) => {
    setCouponData(data);
    setShowMessage(true);
  };

  // const copyToClipboard = (code) => {
  //   const textField = document.createElement("textarea");
  //   textField.innerText = code;
  //   document.body.appendChild(textField);
  //   textField.select();
  //   document.execCommand("copy");
  //   textField.remove();
  // };

  const createCouponFields = (coupon) => {
    return (
      <>
        <h2>{coupon.name}</h2>
        <p>{coupon.text}</p>
        {coupon.link && (
          <button
            onClick={() => {
              handleLinkOpened(coupon.link);
            }}
          >
            {translate("coupons.use")}
          </button>
        )}
        {/* <button
          onClick={() => {
            copyToClipboard(coupon.code);
          }}
        >
          {coupon.code}
        </button> */}
      </>
    );
  };

  const handleLinkOpened = (link) => {
    trackButtonClick(link);
    window.open(link, "_blank");
  };

  const handleCloseMessage = () => {
    setCouponData({});
    setShowMessage(false);
  };

  return (
    <div className="screen screen-coupons">
      <div className="top-text">
        <Typography variant="h5" gutterBottom>
          {translate("coupons.findCoupons")}
        </Typography>
        <img
          src="flowers_and_co_coupon.png"
          alt="Купон за FLOWER'S & CO"
          className="coupon-image"
          onClick={() =>
            handleClick({
              name: "FLOWER'S & CO",
              text: "10% при първа поръчка.",
              link: "https://flco-gallery.com/",
              code: "FLCO10OFF", // Add the discount code here
            })
          }
        />
        <img
          src="pausejeans_coupon.png"
          alt="Купон за PauseJeans"
          className="coupon-image"
          onClick={() =>
            handleClick({
              name: "PauseJeans",
              text: "До 70% отстъпка на различни артикули.",
              link: "https://pausejeans-online.com/41-namalenie",
            })
          }
        />
        <img
          src="kensol_coupon.png"
          alt="Купон за Kensol"
          className="coupon-image"
          onClick={() =>
            handleClick({
              name: "Kensol",
              text: "До 50% отстъпка на различни артикули.",
              link: "https://kensol.eu/product-category/namalenie/",
            })
          }
        />
        <img
          src="dika_coupon.png"
          alt="Купон за Dika"
          className="coupon-image"
          onClick={() =>
            handleClick({
              name: "Dika",
              text: "Безплатна доставка за покупки над 100 лв. на Dika.bg.",
              link: "https://www.dika.bg/bg/home.html",
            })
          }
        />
        <img
          src="dizi_coupon.png"
          alt="Купон за Dizi"
          className="coupon-image"
          onClick={() =>
            handleClick({
              name: "Dizi",
              text: "До 50% отстъпка на различни артикули.",
              link: "https://www.dizi.bg/bg-catalog-details-28.html",
            })
          }
        />
      </div>
      {showMessage && <Modal onClose={handleCloseMessage}>{createCouponFields(couponData)}</Modal>}
    </div>
  );
};

export default Coupons;
