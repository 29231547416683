import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { PostHogProvider } from "posthog-js/react";
import App from "./App";
import { LanguageProvider } from "./components/hooks/LanguageContext";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
    <LanguageProvider>
      <Router>
        <App />
      </Router>
    </LanguageProvider>
  </PostHogProvider>
);
