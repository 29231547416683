import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./ChatBot.css";
import CarouselElements from "../CarouselElement/CarouselElement";

function Chatbot({ user }) {
  const [conversation, setConversation] = useState([]);
  const [input, setInput] = useState("");
  const [showQuestions, setShowQuestions] = useState(true);
  const [questions, setQuestions] = useState([
    {
      text: "What accessories would complement a little black dress?",
      img: "chatbot_question_1.png",
    },
    { text: "What are the color trends for this year?", img: "chatbot_question_2.png" },
    { text: "What kind of shoes go well with skinny jeans?", img: "chatbot_question_3.png" },
    { text: "Can you recommend an outfit for a summer wedding?", img: "chatbot_question_4.png" },
    { text: "What's trending in casual wear this year?", img: "chatbot_question_5.png" },
    // Add other questions here
  ]);
  // const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Create a ref for the textarea
  const textareaRef = useRef(null);

  // Function to scroll to the bottom
  const scrollToTextArea = () => {
    if (textareaRef.current) {
      textareaRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (input !== "" || conversation.length > 1) setShowQuestions(false);
    else setShowQuestions(true);
  }, [input]);

  useEffect(() => {
    const userString = localStorage.getItem("user");
    if (userString && userString.includes("token")) {
      const user = JSON.parse(userString);

      // Add a welcome message to start the conversation
      setConversation([
        {
          text: `Hello ${user.firstName}! I'm Lilly - your person fashion assistant in Stailor. You can ask me anything related to women's fashion and I will do my best to provide you with quality fashion advices.`,
          sender: "bot",
        },
      ]);
    } else {
      // redirect to login page if there is no active user
      window.location.href = "/login";
    }
  }, []);

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleFormSubmit = async (event, questionText) => {
    if (event) event.preventDefault();

    // Use the questionText when it's available, otherwise use the input
    const message = questionText ? questionText : input;

    if (!message) {
      return;
    }

    // Generate a unique ID for the user's message
    const id = `user-${conversation.length}`;

    // Update the conversation state with the new message
    setConversation((prevState) => [...prevState, { id, text: message, sender: "user" }]);

    // Clear the input field
    setInput("");

    // Set loading to true when sending a request to the server
    setIsLoading(true);

    try {
      // Send the user's message to the bot and get a response
      const response = await axios.post(
        // "http://localhost:5000/ai",
        "https://sizeandfit.stailor.io/ai",
        {
          message: message,
          prompt: `Userchat`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      // Generate a unique ID for the bot's message
      const botId = `bot-${conversation.length}`;

      // Set loading to false when the response has been received
      setIsLoading(false);

      // Update the conversation state with the bot's response
      setConversation((prevState) => [
        ...prevState,
        { id: botId, text: response.data, sender: "bot" },
      ]);
    } catch (err) {
      console.log(err);
      // Set loading to false when the response has been received
      setIsLoading(false);
    }
  };

  // End the conversation when the component unmounts
  useEffect(() => {
    return async () => {
      if (user.token) {
        try {
          await axios.post(
            // "http://localhost:5000/end_conversation",
            "https://sizeandfit.stailor.io/end_conversation",
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.token}`,
              },
            }
          );
        } catch (error) {
          console.error(error);
        }
      }
    };
  }, [user.token]);

  const styledResponse = conversation.map((message, index) => {
    if (message.sender === "user") {
      return (
        <div key={index} className="chatbot-message user">
          <p className="chatbot-username">You</p>
          <p className="chatbot-text">{message.text}</p>
        </div>
      );
    } else {
      // Style the AI response with paragraphs and bullet points
      const paragraphs = message.text.split("\n\n");
      const styledParagraphs = paragraphs.map((paragraph, idx) => {
        if (paragraph.startsWith("- ")) {
          return (
            <li key={idx} className="bullet-point">
              {paragraph.substring(2)}
            </li>
          );
        } else {
          return <p key={idx}>{paragraph}</p>;
        }
      });

      return (
        <div key={index} className={`chatbot-message ${message.sender}`}>
          <p className="chatbot-username">Lilly</p>
          <div className="chatbot-text">{styledParagraphs}</div>
        </div>
      );
    }
  });

  const handleQuestionClick = async (question) => {
    // Hide the carousel questions
    setShowQuestions(false);

    // Send the question to the chatbot
    await handleFormSubmit(null, question);
  };

  return (
    <div className="chatbot-container">
      <div className="chatbot-conversation">
        {/* {isLoading && <div className="loading-dots">...</div>} */}
        {styledResponse}
        {isLoading && <div className="loading-dots">.</div>}
        {showQuestions && (
          <div className="chatbot-carousel">
            <span>Choose a question or type yours below</span>
            <CarouselElements>
              {questions.map((question, index) => (
                <div
                  key={index}
                  className="chatbot-carousel-item"
                  onClick={() => handleQuestionClick(question.text)}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/chatbot/" + question.img}
                    alt={question.text}
                  />
                </div>
              ))}
            </CarouselElements>
          </div>
        )}
      </div>
      <form onSubmit={handleFormSubmit} className="chatbot-input-form">
        <textarea
          ref={textareaRef}
          onClick={scrollToTextArea}
          type="text"
          value={input}
          onChange={handleInputChange}
          className="chatbot-input"
          placeholder="Type your message here"
        />
        <button type="submit" className="chatbot-send-button">
          <i className="bi bi-send"> </i>
        </button>
      </form>
    </div>
  );
}

export default Chatbot;
