import Loading from "../Loading/Loading";

const LoadingScreen = () => {
  return (
    <div className="screen">
      <Loading />
    </div>
  );
};
export default LoadingScreen;
