import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "../hooks/LanguageContext";
import { trackButtonClick } from "../../functions/trackClicks";
import Typography from "@mui/material/Typography";

import Modal from "../ModalWindow/ModalWindow";

const Coupon = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [couponData, setCouponData] = useState({});
  const { translate } = useLanguage();

  const handleClick = (data) => {
    setCouponData(data);
    setShowMessage(true);
  };

  const style = { marginTop: "20px" };

  const createCouponFields = (coupon) => {
    return (
      <>
        <h2>{coupon.name}</h2>
        <p>{coupon.text}</p>
        {coupon.link && (
          <button
            className="btn btn-big btn-bgcolor-stailor"
            onClick={() => {
              handleLinkOpened(coupon.link);
            }}
          >
            {translate("coupons.use")}
          </button>
        )}
      </>
    );
  };

  const handleLinkOpened = (link) => {
    trackButtonClick(link);
    window.open(link, "_blank");
  };

  const handleCloseMessage = () => {
    setCouponData({});
    setShowMessage(false);
  };

  return (
    <>
      <img
        src={`${process.env.PUBLIC_URL}/flowers_and_co_coupon.png`}
        alt="Купон за FLOWER'S & CO"
        style={style}
        onClick={() =>
          handleClick({
            name: "FLOWER'S & CO",
            text: "10% ПРОМОКОД: „STAILOR10“",
            link: "https://flco-gallery.com/",
            code: "FLCO10OFF", // Add the discount code here
          })
        }
      />
      {showMessage && <Modal onClose={handleCloseMessage}>{createCouponFields(couponData)}</Modal>}
    </>
  );
};

export default Coupon;
