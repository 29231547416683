import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "../hooks/LanguageContext"; // Import the useLanguage hook
import "./Garment.css";
import Typography from "@mui/material/Typography";

const Garment = ({ handleClick, preferenceData }) => {
  const { translate } = useLanguage(); // Use the translate function from the useLanguage hook

  // Creating the categories array with values depending on the gender (shop department)
  let categories = [];
  if (preferenceData.gender === "male") {
    categories = [
      { name: translate("garment.shirt") },
      { name: translate("garment.jeans") },
      { name: translate("garment.coat") },
      { name: translate("garment.trouser") },
      { name: translate("garment.tShirt") },
    ];
  } else if (preferenceData.gender === "female") {
    categories = [
      { name: translate("garment.dress") },
      { name: translate("garment.jeans") },
      { name: translate("garment.top") },
      { name: translate("garment.skirt") },
      { name: translate("garment.coat") },
    ];
  } else {
    console.log(translate("garment.genderSelectionError"));
  }

  // Map the categories array and create the html code for each garment category
  const mapCategories = categories.map((category, index) => {
    return (
      <button
        className="btn btn-big"
        key={index}
        onClick={handleClick}
        name="garmentType"
        value={category.name}
      >
        {category.name}
      </button>
    );
  });

  return (
    <div className="screen screen-garment-preference">
      <div className="top-text">
        <Typography variant="h6">{translate("garment.lookingFor")}</Typography>
      </div>
      <div className="container-garment-preference">
        <div className="container-gender">{mapCategories}</div>
        <Link to={"/style-recommendation"}>
          <button className="button_style">{translate("common.startOver")}</button>
        </Link>
      </div>
    </div>
  );
};

export default Garment;
