import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PfpHolder from "../PfpHolder/PfpHolder";
import { useLanguage } from "../hooks/LanguageContext";
import "./UserSettings.css";

const UserSettings = ({ user, isAuthenticated }) => {
  const [animate, setAnimate] = useState(false);
  const { translate } = useLanguage();

  useEffect(() => {
    setAnimate(true);
  }, []);

  const handleIconClick = (setting) => {
    // Handle click event for each setting
    console.log(`Clicked ${setting}`);
  };

  if (!isAuthenticated) {
    return (
      <div className="screen">
        <h1>{translate("userSettings.loginRequired")}</h1>
        <Link to="/login">
          <button className="btn btnBig bgcolorStailor">{translate("common.login")}</button>
        </Link>
        <p className="txtQuestion">{translate("common.noAccountMsg")}</p>
        <Link to="/signup">
          <p className="txtQuestion">
            <u>{translate("common.signUpLink")}</u>
          </p>
        </Link>
      </div>
    );
  }
  
  return (
    <div className="screen user-settings">
      <div className="profile-section">
        <PfpHolder pfpUrl={user?.picture} gender={user?.gender} />
        <h2 className="profile-heading">{translate("userSettings.overview")}</h2>
      </div>
      <div className={`settings-section ${animate ? "animate" : ""}`}>
        <h3 className="section-heading">{translate("userSettings.settings")}</h3>
        <div className="settings-item" onClick={() => handleIconClick("Personal Details")}>
          <i className="fas fa-details icon" />
          <Link to="/personal-info">
            <p>{translate("userSettings.personalDetails")}</p>
          </Link>
        </div>
        {/* <div className="settings-item" onClick={() => handleIconClick("Login Details")}>
          <i className="fas fa-details icon" />
          <Link to="/edit-profile">
            <p>{translate("userSettings.loginDetails")}</p>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default UserSettings;
