import LikeButton from "../LikeButton/LikeButton";
// import ShareToInstagram from "../SocialShare/ShareToInstagram";
import ShareButton from "../ShareButton/ShareButton";

const ActionButtonsBar = ({ previewObj, likedItems, product, img, sharingData }) => {
  return (
    <div className="container-product-actions">
      {previewObj === "style" ? (
        <>
          {/* <ShareToInstagram link={window.location.href} image={img} /> */}
          <ShareButton sharingData={sharingData} />
        </>
      ) : (
        <span></span>
      )}
      <LikeButton previewObj={previewObj} likedItems={likedItems} objectId={product._id} />
    </div>
  );
};
export default ActionButtonsBar;
