export function replaceLinks(text) {
  // Define a dictionary to map link names to their respective URLs
  const linkDictionary = {
    "Общи условия": "https://stailor.io/en/term-and-conditions/",
    "Политика за поверителност": "https://stailor.io/en/privacy-policy/",
    "General terms": "https://stailor.io/en/term-and-conditions/",
    "Privacy policy": "https://stailor.io/en/privacy-policy/",
  };

  // Regular expression to match the placeholders
  const linkPattern = /\{\{link:(.*?)\}\}/g;

  const linkStyle = { fontWeight: 600, textDecoration: "underline" };

  // Replace placeholders with HTML links
  return text.split(linkPattern).map((part, index) => {
    if (index % 2 === 1) {
      const linkName = part.trim();
      const url = linkDictionary[linkName];
      if (url) {
        return (
          <a style={linkStyle} href={url} target="_blank" rel="noopener noreferrer" key={index}>
            {linkName}
          </a>
        );
      }
      // If the link doesn't exist in the dictionary, return it as plain text
      return linkName;
    }
    return part;
  });
}
