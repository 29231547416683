import React, { useState } from "react";
import axios from "axios";

const AddService = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const user = localStorage.getItem("user");
  const parsedUser = JSON.parse(user);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "title") {
      setTitle(value);
    } else if (name === "description") {
      setDescription(value);
    }
  };

  const validateForm = () => {
    let formErrors = {};

    if (title.trim().length === 0) {
      formErrors.title = "Title is required";
    }

    if (description.trim().length === 0) {
      formErrors.description = "Description is required";
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    const serviceData = {
      title: title.trim(),
      description: description.trim(),
    };

    try {
      // const response = await axios.post("http://localhost:5000/profile/add-service", serviceData, {
      const response = await axios.post(
        "https://sizeandfit.stailor.io/profile/add-service",
        serviceData,
        {
          headers: {
            Authorization: `Bearer ${parsedUser?.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        setSuccessMessage("Service created successfully");
        setTitle("");
        setDescription("");
      } else {
        setSuccessMessage("");
        // Handle error messages here if needed
      }
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };

  return (
    <div className="screen screen-add-style">
      <form className="form-add-style" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Име</label>
          <input type="text" name="title" value={title} onChange={handleInputChange} required />
          {errors.title && <p className="error">{errors.title}</p>}
        </div>

        <div className="form-group">
          <label>Описание</label>
          <textarea name="description" value={description} onChange={handleInputChange} required />
          {errors.description && <p className="error">{errors.description}</p>}
        </div>

        {successMessage && <p className="success">{successMessage}</p>}

        <button className="btn btn-big btn-bgcolor-stailor" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddService;
